import React, { useState } from 'react';
import './ImpressumComponent.scss';

const ImpressumComponent = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <div className="impressum-wrapper">
            <div onClick={handleOpen} className='text-text'>Impressum</div>
            {isOpen && (
                <div className="impressum-container">
                    <div className="impressum-title">Impressum</div>
                    <div className="impressum-content">
                        <div className="impressum-section">
                            <h2>Disclaimer:</h2>
                            <h3>Liability for content:</h3>
                            <p>The content of our site was created with the greatest of care. However, we cannot
                                guarantee the contents' accuracy, completeness, and topicality. As service providers, we
                                are liable for our own content on these pages according to &sect; 7 para.1 TMG
                                (Telemediengesetz) under general laws. However, according to &sect; 8 to 10 TMG, we are
                                not obligated as service providers to permanently monitor transmitted or stored external
                                information or to search for evidence that indicates illegal activities. Obligations to
                                remove or block the use of information under general law remain unaffected. However,
                                liability in this respect may be assumed only from the point in time of knowledge of a
                                concrete infringement. If we become aware of any such legal infringements, we will
                                remove the respective contents immediately.</p>

                            <h3>Liability for links:</h3>
                            <p>Our service offers links to external third-party websites. We have no influence on the
                                content of those websites, therefore we cannot guarantee for those contents. Providers
                                or administrators of linked websites are always responsible for their own content. The
                                linked websites had been checked for possible violations of law at the time of the
                                establishment of the link. Illegal contents were not detected at the time of the
                                linking. A permanent monitoring of the contents of linked websites cannot be imposed
                                without reasonable indications that there has been a violation of law. Illegal links
                                will be removed immediately at the time we get knowledge of them.</p>

                            <h3>Copyright:</h3>
                            <p>The content and works on these pages created by the site operators are subject to German
                                copyright law. Duplication, processing, distribution, or any form of commercialization
                                of such material beyond the scope of the copyright law shall require the prior written
                                consent of its respective author or creator. Downloads and copies of this site are only
                                permitted for private, non-commercial use. Insofar as the content on this site was not
                                created by the operator, the copyrights of third parties are respected. In particular,
                                third-party content is marked as such. If you should nevertheless become aware of a
                                copyright infringement, we kindly ask you to notify us. Upon becoming aware of
                                violations, we will immediately remove the content in question.</p>
                        </div>
                        <div className="impressum-section">
                            <h2>Information according to &sect; 5 TMG (Telemediengesetz):</h2>
                            <p><strong>PS Software Solutions GbR</strong></p>  {/* Gro� und fett */}
                            <p>Balger Hauptstrasse 83</p>
                            <p>76532 Baden-Baden</p>
                            <p>Germany</p>
                        </div>
                        <div className="impressum-section">
                            <h2 style={{
                                fontSize: "1em",
                                fontWeight: "normal"
                            }}>Vertretungsberechtigte:</h2> {/* Dezente Schriftgr��e */}
                            <p className="representatives">Paul Schmitz</p>
                            <p className="representatives">Julius Pflueger</p>
                            <p>E-Mail: <a className='contact-mail'
                                          href="mailto:info@ps-software-solutions.de">info@ps-software-solutions.de</a>
                            </p>
                        </div>
                    </div>
                    <div className="impressum-button-container">
                        <button className="close-impressum-button" onClick={handleClose}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImpressumComponent;
