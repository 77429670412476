export class Rating {
    id: number;
    store_id: number;
    username: string;
    vegetable: number;
    meat: number;
    bread: number;
    sauce: number;
    price_performance: number;
    comment_id: number;
    created_at: Date;

    constructor(
        id: number,
        store_id: number,
        username: string,
        vegetable: number,
        meat: number,
        bread: number,
        sauce: number,
        price_performance: number,
        comment_id: number,
        created_at: Date
    ) {
        this.id = id;
        this.store_id = store_id;
        this.username = username;
        this.vegetable = vegetable;
        this.meat = meat;
        this.bread = bread;
        this.sauce = sauce;
        this.price_performance = price_performance;
        this.comment_id = comment_id;
        this.created_at = created_at;
    }
}