import React, { useCallback, useEffect, useRef, useState } from "react";
import './LiveSearch.scss';
import { useNavigate } from "react-router-dom";

interface ResultItem {
    id: number;
    name: string;
}

interface Props<T extends ResultItem> {
    results?: T[];
    renderItem(item: T): JSX.Element;
    onChange?: React.ChangeEventHandler;
    onSelect?: (item: T) => void;
    value?: string;
    inputClassName?: string;
    resultsClassName?: string;
    searchType: string;
}

const LiveSearch = <T extends ResultItem>({
                                              results = [],
                                              renderItem,
                                              value,
                                              onChange,
                                              onSelect,
                                              inputClassName,
                                              resultsClassName,
                                              searchType
                                          }: Props<T>): JSX.Element => {
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const resultContainer = useRef<HTMLAnchorElement>(null);
    const [showResults, setShowResults] = useState(false);
    const [defaultValue, setDefaultValue] = useState("");
    const navigate = useNavigate();
    const [preventSearchResults, setPreventSearchResults] = useState(true);

    const handleSelection = async (selectedIndex: number) => {
        const selectedItem = results[selectedIndex];
        if (!selectedItem) return resetSearchComplete();
        onSelect && onSelect(selectedItem);
        resetSearchComplete(); // Schlie�t das Dropdown

        setDefaultValue(""); // Suchfeld leeren

        if (searchType === 'stores') {
            navigate(`/store/${selectedItem.id}`);
        } else {
            navigate(`/user/${selectedItem.id}`);
        }
    };

    const handleShowAllResults = () => {
        resetSearchComplete();
        setShowResults(false);

        setTimeout(() => {
            let targetUrl;

            if (searchType === 'stores') {
                targetUrl = `/stores?query=${encodeURIComponent(defaultValue.trim())}`;
            } else {
                targetUrl = `/users?query=${encodeURIComponent(defaultValue.trim())}`;
            }

            window.location.href = targetUrl;
            setPreventSearchResults(true);
        }, 0);
    };

    const resetSearchComplete = useCallback(() => {
        setFocusedIndex(-1);
        setShowResults(false);
    }, []);

    const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
        const { key } = e;
        let nextIndexCount = 0;

        if (key === "ArrowDown") {
            nextIndexCount = (focusedIndex + 1) % results.length;
        }
        if (key === "ArrowUp") {
            nextIndexCount = (focusedIndex + results.length - 1) % results.length;
        }
        if (key === "Escape") {
            resetSearchComplete();
        }
        if (key === "Enter") {
            e.preventDefault();
            handleSelection(focusedIndex);
        }

        setFocusedIndex(nextIndexCount);
    };

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setDefaultValue(e.target.value);
        setPreventSearchResults(false);
        onChange && onChange(e);
    };

    useEffect(() => {
        if (!resultContainer.current) return;

        resultContainer.current.scrollIntoView({
            block: "center",
        });
    }, [focusedIndex]);

    useEffect(() => {
            if (results.length > 0 && !showResults && !preventSearchResults) setShowResults(true);
            if (results.length <= 0) setShowResults(false);
    }, [results]);

    useEffect(() => {
        if (value) setDefaultValue(value);
    }, [value]);

    return (
      <>
          <input
            value={defaultValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            type="text"
            className={inputClassName}
            placeholder="Suchen..."
          />

          {showResults && (
            <div className={resultsClassName} style={{ maxHeight: '300px', overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
                {results.map((item, index) => (
                  <a
                    key={index}
                    onClick={() => handleSelection(index)}
                    ref={index === focusedIndex ? resultContainer : null}
                    className="cursor-pointer search-item"
                    style={{
                        backgroundColor: index === focusedIndex ? "rgba(0,0,0,0.1)" : "",
                    }}
                  >
                      {renderItem(item)}
                  </a>
                ))}
                {searchType === 'stores' && (
                  <div
                    className={"show-all-field"}
                    onClick={handleShowAllResults}
                  >
                      Zeige alle Ergebnisse
                  </div>
                )}
            </div>
          )}
      </>
    );
};

export default LiveSearch;
