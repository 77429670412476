import React, {useEffect, useState, useMemo} from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table';
import {Link} from 'react-router-dom';
import {fetchRatingsByUserId, fetchStoreById} from '../../api/apiService';
import {useLogin} from '../../contexts/LoginContext';
import {Rating} from "../../models/rating";
import {Store} from "../../models/store";
import {Box, Typography} from '@mui/material';
import {BACKEND_URL} from '../../api/apiConstants';

interface RatingsTableProps {
  userId?: number;
  username?: string;
}

const RatingsTable: React.FC<RatingsTableProps> = ({userId, username}) => {
  const {user} = useLogin();
  const [ratings, setRatings] = useState<Rating[]>([]);
  const [stores, setStores] = useState<Map<number, Store>>(new Map());

  useEffect(() => {
    const id = userId || user?.id;
    if (id !== undefined) {
      fetchRatings(id);
    }
  }, [userId, user]);

  const fetchRatings = async (id: number) => {
    try {
      const mappedRatings: Rating[] = await fetchRatingsByUserId(id);
      setRatings(mappedRatings);
      const storeMap = new Map<number, Store>();
      await Promise.all(mappedRatings.map(async (rating) => {
        if (!storeMap.has(rating.store_id)) {
          const store = await fetchStoreById(rating.store_id);
          storeMap.set(rating.store_id, store);
        }
      }));
      setStores(storeMap);
    } catch (error) {
      console.error('error fetching data in RatingsTable', error);
    }
  };

  const calculateAverage = (rating: Rating) => {
    // Sammle alle Bewertungen in einem Array
    const values = [rating.meat, rating.bread, rating.vegetable, rating.sauce];

    // Filtere die Bewertungen heraus, die 0 sind
    const nonZeroValues = values.filter(value => value !== 0);

    // Wenn alle Werte 0 sind, gib 0 als Durchschnitt zurück
    if (nonZeroValues.length === 0) {
      return 0;
    }

    // Berechne den Durchschnitt der verbleibenden Werte
    const average = nonZeroValues.reduce((acc, value) => acc + value, 0) / nonZeroValues.length;

    // Runde den Durchschnitt auf eine Dezimalstelle
    return Math.round(average * 10) / 10;
  };

  const formatRating = (value: number) => {
    return value === 0 ? '-' : value.toFixed(1);
  };

  const columns = useMemo<MRT_ColumnDef<Rating & { store_name: string }>[]>(
    () => [
      {
        accessorKey: 'store_name',
        header: 'Dönerladen',
        size: 200,
        Cell: ({cell}) => {
          const storeId = cell.row.original.store_id;
          const store = stores.get(storeId);
          const storeName = cell.getValue<string>();
          return (
            <Link
              to={`/store/${storeId}`}
              style={{
                textDecoration: 'none',
                color: 'inherit',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              <img
                src={`${BACKEND_URL}/${store?.image_path}`}
                style={{width: '30px', height: '30px', marginRight: '10px', borderRadius: '50%'}}
              />
              {storeName}
            </Link>
          );
        },
      },
      {
        accessorKey: 'average',
        header: 'Gesamt',
        size: 100,
        Cell: ({cell}) => {
          const rating = cell.row.original;
          const average = calculateAverage(rating);
          return formatRating(average);
        },
        sortingFn: (rowA, rowB) => {
          const avgA = calculateAverage(rowA.original);
          const avgB = calculateAverage(rowB.original);
          return avgA - avgB;
        },
      },
      {
        accessorKey: 'city',
        header: 'Stadt',
        size: 150,
        Cell: ({cell}) => {
          const storeId = cell.row.original.store_id;
          const store = stores.get(storeId);
          return store?.city || 'Unbekannt';
        },
      },
      {
        accessorKey: 'bread',
        header: 'Brot',
        size: 100,
        Cell: ({cell}) => formatRating(cell.getValue<number>()),
      },
      {
        accessorKey: 'meat',
        header: 'Fleisch',
        size: 100,
        Cell: ({cell}) => formatRating(cell.getValue<number>()),
      },
      {
        accessorKey: 'vegetable',
        header: 'Gemüse',
        size: 100,
        Cell: ({cell}) => formatRating(cell.getValue<number>()),
      },
      {
        accessorKey: 'sauce',
        header: 'Soße',
        size: 100,
        Cell: ({cell}) => formatRating(cell.getValue<number>()),
      },
      {
        accessorKey: 'price_performance',
        header: 'P/L',
        size: 100,
        Cell: ({cell}) => formatRating(cell.getValue<number>()),
      },
    ],
    [stores]
  );

  const ratingsWithStoreNames = useMemo(() => {
    return ratings.map(rating => ({
      ...rating,
      store_name: stores.get(rating.store_id)?.name || 'Unknown Store'
    }));
  }, [ratings, stores]);

  const table = useMaterialReactTable({
    columns,
    data: ratingsWithStoreNames,
    enableSorting: true,
    enableRowSelection: false,
    initialState: {
      pagination: {pageSize: 5, pageIndex: 0},
      sorting: [{id: 'store_name', desc: false}],
      showGlobalFilter: true,
    },
    globalFilterFn: 'contains',
    enableColumnActions: false, // Disable the three dots menu
    enableColumnFilters: false, // Disable column filters
    enableDensityToggle: false, // Disable density toggle
  });

  return (
    <Box sx={{m: '2rem 0', mt: '5rem'}}>
      <Typography variant="h4" sx={{mb: '1rem'}}>
        {userId ? `${username}'s Bewertungen` : 'Meine Bewertungen'}
      </Typography>
      <MaterialReactTable table={table}/>
    </Box>
  );
};

export default RatingsTable;
