import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import CommentCardComponent from './CommentCardComponent';
import CommentSectionFilterBarComponent from './CommentSectionFilterComponent';
import './CommentCardComponent.scss';
import { Rating } from '../../../models/rating';
import { Comment } from '../../../models/comment';
import { fetchCommentsByIds, fetchRatingsByStoreId } from '../../../api/apiService';

const CommentSectionComponent: React.FC<{ storeId: string | null, refresh: boolean }> = ({ storeId, refresh }) => {
  const [ratingCommentsMap, setRatingCommentsMap] = useState<Map<Rating, Comment | null>>(new Map());
  const [sortedComments, setSortedComments] = useState<[Rating, Comment | null][]>([]);
  const [currentFilter, setCurrentFilter] = useState<string>('popular');

  const fetchComments = async () => {
    try {
      if (storeId !== null) {
        const mappedRatings: Rating[] = await fetchRatingsByStoreId(parseInt(storeId, 10));
        if (mappedRatings.length > 0) {
          const commentIds: number[] = mappedRatings
              .filter((rating: Rating) => rating.comment_id !== null && rating.comment_id !== undefined)
              .map((rating: Rating) => rating.comment_id!);

          if (commentIds.length > 0) {
            const mappedComments: Comment[] = await fetchCommentsByIds(commentIds);
            const ratingCommentsMap = new Map<Rating, Comment | null>();
            mappedRatings.forEach((rating: Rating) => {
              const relatedComment: Comment | undefined = mappedComments.find((comment: Comment) => comment.id === rating.comment_id);
              ratingCommentsMap.set(rating, relatedComment || null);
            });
            setRatingCommentsMap(ratingCommentsMap);
            setSortedComments(Array.from(ratingCommentsMap.entries()));
          }
        } else {
          setRatingCommentsMap(new Map());
          setSortedComments([]);
        }
      }
    } catch (error) {
      console.error('error fetch data in CommentSectionComponent', error);
    }
  };

  const sortComments = (ratingCommentsMap: Map<Rating, Comment | null>, filter: string) => {
    let sorted = Array.from(ratingCommentsMap.entries());
    switch (filter) {
      case 'popular':
        sorted = sorted.sort((a, b) => (b[1]?.upvotes || 0) - (a[1]?.upvotes || 0));
        break;
      case 'oldest':
        sorted = sorted.sort((a, b) => new Date(a[1]?.created_at || '').getTime() - new Date(b[1]?.created_at || '').getTime());
        break;
      case 'newest':
        sorted = sorted.sort((a, b) => new Date(b[1]?.created_at || '').getTime() - new Date(a[1]?.created_at || '').getTime());
        break;
      default:
        break;
    }
    setSortedComments(sorted);
  };

  const handleFilterChange = (filter: string) => {
    setCurrentFilter(filter);
    sortComments(ratingCommentsMap, filter);
  };

  useEffect(() => {
    fetchComments();
  }, [storeId, refresh]);

  useEffect(() => {
    if (ratingCommentsMap.size > 0) {
      sortComments(ratingCommentsMap, currentFilter);
    }
  }, [ratingCommentsMap, currentFilter]);

  return (
    <div>
      <h3 className='mb-4'>Kommentare</h3>
      <Row>
        <CommentSectionFilterBarComponent currentFilter={currentFilter} onFilterChange={handleFilterChange} />
      </Row>
      <Row className='row-margin'>
        {sortedComments.map(([rating, comment]) => (
          <CommentCardComponent key={rating.id} comment={comment} rating={rating} storeId={storeId} />
        ))}
      </Row>
    </div>
  );
};

export default CommentSectionComponent;
