import React, {useState} from 'react';
import {Button, Card, Col, Form, FormControl, InputGroup, Row} from 'react-bootstrap';
import {CONSTANTS} from '../../../constants';
import './StoreOverviewFilterComponent.scss';
import RatingBadgeComponent from '../../StoreComponent/CommentSectionComponent/CommentRatingComponent';
import AddStoreComponent from '../AddStoreComponent/AddStoreComponent';

interface StoreOverviewFilterComponentProps {
    onFilterChange: (zipCode: string, total: number, bread: number, vegetables: number, sauce: number, meat: number, pricePerformance: number) => void;
    onAddStore: () => void;
}

const StoreOverviewFilterComponent: React.FC<StoreOverviewFilterComponentProps> = ({onFilterChange, onAddStore}) => {
    const [filterOpen, setFilterOpen] = useState(false);
    const [zipCode, setZipCode] = useState<string>('');
    const [total, setTotal] = useState<number>(0);
    const [bread, setBread] = useState<number>(0);
    const [vegetables, setVegetables] = useState<number>(0);
    const [sauce, setSauce] = useState<number>(0);
    const [meat, setMeat] = useState<number>(0);
    const [pricePerformance, setPricePerformance] = useState<number>(0);

    const handleFilterToggle = () => {
        setFilterOpen(!filterOpen);
    };

    const handleFilterChange = () => {
        onFilterChange(zipCode, total, bread, vegetables, sauce, meat, pricePerformance);
        handleFilterToggle();
    };

    const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;

        // Nur Zahlen und maximal 6 Ziffern zulassen
        const sanitizedInput = input.replace(/[^0-9]/g, '').slice(0, 6);

        setZipCode(sanitizedInput);
    };

    return (
        <div>
            <Row>
                <Col xs={3}>
                    <Button variant="secondary" className='text-white' onClick={handleFilterToggle}>
                        Filter
                    </Button>
                </Col>
                <Col xs={5}>
                </Col>
                <Col xs={4} className='text-end'>
                    <AddStoreComponent onAddStore={onAddStore}/>
                </Col>
            </Row>
            {filterOpen && (
                <div className='mt-3'>
                    <Card bg="primary">
                        <div className='mt-3'>
                            <Row>
                                <Col xs={10}>
                                    <Form.Label>PLZ</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            placeholder="PLZ eingeben"
                                            aria-label="PLZ eingeben"
                                            aria-describedby="plz-input"
                                            value={zipCode}
                                            onChange={handleZipCodeChange}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10} md={5}>
                                    <Form.Label className='range-label'>
                                        {CONSTANTS.BREAD}:
                                    </Form.Label>
                                    <div className='d-flex align-items-center'>
                                        <RatingBadgeComponent rating={bread} bgColor="bread" text={''} badgeSize='xsmall' />
                                        <Form.Range className="custom-range ms-3" min="0" max="10" step="0.5" value={bread} onChange={(e) => setBread(Number(e.target.value))} />
                                    </div>
                                </Col>
                                <Col xs={10} md={5}>
                                    <Form.Label className='range-label'>
                                        {CONSTANTS.MEAT}:
                                    </Form.Label>
                                    <div className='d-flex align-items-center'>
                                        <RatingBadgeComponent rating={meat} bgColor="meat" text={''} badgeSize='xsmall' />
                                        <Form.Range className="custom-range ms-3" min="0" max="10" step="0.5" value={meat} onChange={(e) => setMeat(Number(e.target.value))} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10} md={5}>
                                    <Form.Label className='range-label'>
                                        {CONSTANTS.VEGETABLES}:
                                    </Form.Label>
                                    <div className='d-flex align-items-center'>
                                        <RatingBadgeComponent rating={vegetables} bgColor="vegetable" text={''} badgeSize='xsmall' />
                                        <Form.Range className="custom-range ms-3" min="0" max="10" step="0.5" value={vegetables} onChange={(e) => setVegetables(Number(e.target.value))} />
                                    </div>
                                </Col>
                                <Col xs={10} md={5}>
                                    <Form.Label className='range-label'>
                                        {CONSTANTS.SAUCE}:
                                    </Form.Label>
                                    <div className='d-flex align-items-center'>
                                        <RatingBadgeComponent rating={sauce} bgColor="sauce" text={''} badgeSize='xsmall' />
                                        <Form.Range className="custom-range ms-3" min="0" max="10" step="0.5" value={sauce} onChange={(e) => setSauce(Number(e.target.value))} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10} md={5}>
                                    <Form.Label className='range-label'>
                                        {CONSTANTS.TOTAL}:
                                    </Form.Label>
                                    <div className='d-flex align-items-center'>
                                        <RatingBadgeComponent rating={total} bgColor="secondary" text={''} badgeSize='xsmall' />
                                        <Form.Range className="custom-range ms-3" min="0" max="10" step="0.5" value={total} onChange={(e) => setTotal(Number(e.target.value))} />
                                    </div>
                                </Col>
                                <Col xs={10} md={5}>
                                    <Form.Label className='range-label'>
                                        {CONSTANTS.PRICE_PERFORMANCE}:
                                    </Form.Label>
                                    <div className='d-flex align-items-center'>
                                        <RatingBadgeComponent rating={pricePerformance} bgColor="price-performance" text={''} badgeSize='xsmall' />
                                        <Form.Range className="custom-range ms-3" min="0" max="10" step="0.5" value={pricePerformance} onChange={(e) => setPricePerformance(Number(e.target.value))} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10} className='justify-content-center d-flex'>
                                    <Button variant="secondary" className='text-white mb-3 mt-2' onClick={handleFilterChange}>
                                        Filter anwenden
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </div>
            )}
        </div>
    );
};

export default StoreOverviewFilterComponent;
