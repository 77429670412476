import React, { useEffect, useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import './StoreOverviewFilterComponent.scss';

const StoreOverviewFilterBarComponent: React.FC<{
  currentFilter: string;
  onFilterChange: (filter: string, direction?: boolean) => void;
  setCurrentPage: (page: number) => void;
}> = ({ currentFilter, onFilterChange, setCurrentPage }) => {
  const [selectedFilter, setSelectedFilter] = useState<string>(currentFilter);

  useEffect(() => {
    setSelectedFilter(currentFilter);
  }, [currentFilter]);

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
    onFilterChange(filter);
    setCurrentPage(1); // Reset page to 1 on filter change
  };

  return (
    <ButtonGroup>
      <ToggleButton
        id="new"
        type="radio"
        variant="secondary"
        name="filterOptions"
        value="new"
        checked={selectedFilter === 'new'}
        onChange={() => handleFilterChange('new')}
        className="d-flex justify-content-center text-white"
      >
        <i className="bi bi-asterisk me-2 filter-icon d-flex align-items-center"></i>
        Neu
      </ToggleButton>
      <ToggleButton
        id="best"
        type="radio"
        variant="secondary"
        name="filterOptions"
        value="best"
        checked={selectedFilter === 'best'}
        onChange={() => handleFilterChange('best')}
        className="d-flex justify-content-center text-white"
      >
        <i className="bi bi-hand-thumbs-up-fill me-2 filter-icon d-flex align-items-center"></i>
        Top
      </ToggleButton>
      <ToggleButton
        id="worst"
        type="radio"
        variant="secondary"
        name="filterOptions"
        value="worst"
        checked={selectedFilter === 'worst'}
        onChange={() => handleFilterChange('worst')}
        className="d-flex justify-content-center text-white"
      >
        <i className="bi bi-hand-thumbs-down-fill me-2 filter-icon d-flex align-items-center"></i>
        Flop
      </ToggleButton>
      <ToggleButton
        id="ratingAmount"
        type="checkbox"
        variant="secondary"
        name="filterOptions"
        value="ratingAmount"
        checked={selectedFilter === 'ratingAmount'}
        onChange={() => handleFilterChange('ratingAmount')}
        className="d-flex justify-content-center text-white"
      >
        <i className="bi bi-123 me-2 filter-icon d-flex align-items-center"></i>
        Meiste
      </ToggleButton>
    </ButtonGroup>
  );
};

export default StoreOverviewFilterBarComponent;
