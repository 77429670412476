import React, {useEffect, useState} from 'react';
import {Col, Form} from 'react-bootstrap';
import RatingBadgeComponent from '../CommentSectionComponent/CommentRatingComponent';
import {CONSTANTS} from '../../../constants';
import {Rating} from '../../../models/rating';
import {Comment} from '../../../models/comment';

interface RateComponentProps {
    ratingChangedCallback: (
        breadRating: number,
        vegetablesRating: number,
        sauceRating: number,
        meatRating: number,
        pricePerformanceRating: number,
        commentHeadline: string,
        commentContent: string
    ) => void;
    existingRating: Rating | null
    existingComment: Comment | null
}

const RateComponent: React.FC<RateComponentProps> = ({ ratingChangedCallback, existingRating, existingComment }) => {
    const [breadRating, setBreadRating] = useState<number>(0);
    const [vegetablesRating, setVegetablesRating] = useState<number>(0);
    const [sauceRating, setSauceRating] = useState<number>(0);
    const [meatRating, setMeatRating] = useState<number>(0);
    const [pricePerformanceRating, setPricePerformanceRating] = useState<number>(0);
    const [commentHeadline, setCommentHeadline] = useState('');
    const [commentContent, setCommentContent] = useState('');
    const maxHeadlineCharacters = 50;
    const maxContentCharacters = 1500;

    useEffect(() => {
        if (existingRating) {
          setBreadRating(existingRating.bread);
          setVegetablesRating(existingRating.vegetable);
          setSauceRating(existingRating.sauce);
          setMeatRating(existingRating.meat);
          setPricePerformanceRating(existingRating.price_performance);
        }

        if (existingComment) {
          setCommentHeadline(existingComment.headline);
          setCommentContent(existingComment.content);
        }
    }, [existingRating, existingComment]);

    useEffect(() => {
        ratingChangedCallback(breadRating, vegetablesRating, sauceRating, meatRating, pricePerformanceRating, commentHeadline, commentContent);
    }, [breadRating, vegetablesRating, sauceRating, meatRating, pricePerformanceRating, commentHeadline, commentContent]);



    return (
        <div>
            <Form.Label className='range-label'>
                <Col xs={3}>
                    <div>{CONSTANTS.BREAD}</div>
                </Col>
                <Col xs={5}>
                    <RatingBadgeComponent rating={breadRating} bgColor="bread" text={''} badgeSize='small'></RatingBadgeComponent>
                </Col>
            </Form.Label>
            <Form.Range className="custom-range" min="0" max="10" step="0.5" value={breadRating} onChange={(e) => {
                setBreadRating(Number(e.target.value));
            }} />
            <Form.Label className='range-label'>
                <Col xs={3}>
                    <div>{CONSTANTS.MEAT}</div>
                </Col>
                <Col xs={5}>
                    <RatingBadgeComponent rating={meatRating} bgColor="meat" text={''} badgeSize='small'></RatingBadgeComponent>
                </Col>
            </Form.Label>
            <Form.Range className="custom-range" min="0" max="10" step="0.5" value={meatRating} onChange={(e) => {
                setMeatRating(Number(e.target.value));
            }} />
            <Form.Label className='range-label'>
                <Col xs={3}>
                    <div>{CONSTANTS.VEGETABLES}</div>
                </Col>
                <Col xs={5}>
                    <RatingBadgeComponent rating={vegetablesRating} bgColor="vegetable" text={''} badgeSize='small'></RatingBadgeComponent>
                </Col>
            </Form.Label>
            <Form.Range className="custom-range" min="0" max="10" step="0.5" value={vegetablesRating} onChange={(e) => {
                setVegetablesRating(Number(e.target.value));
            }} />
            <Form.Label className='range-label'>
                <Col xs={3}>
                    <div>{CONSTANTS.SAUCE}</div>
                </Col>
                <Col xs={5}>
                    <RatingBadgeComponent rating={sauceRating} bgColor="sauce" text={''} badgeSize='small'></RatingBadgeComponent>
                </Col>
            </Form.Label>
            <Form.Range className="custom-range" min="0" max="10" step="0.5" value={sauceRating} onChange={(e) => {
                setSauceRating(Number(e.target.value));
            }} />
            <Form.Label className='range-label'>
                <Col xs={3}>
                    <div>{CONSTANTS.PRICE_PERFORMANCE}</div>
                </Col>
                <Col xs={5}>
                    <RatingBadgeComponent rating={pricePerformanceRating} bgColor="price-performance" text={''} badgeSize='small'></RatingBadgeComponent>
                </Col>
            </Form.Label>
            <Form.Range className="custom-range" min="0" max="10" step="0.5" value={pricePerformanceRating} onChange={(e) => {
                setPricePerformanceRating(Number(e.target.value));
            }} />
            <Form.Group as={Col} xs={12} controlId="commentHeadline" className='mt-3'>
                <Form.Control
                    type="text"
                    placeholder="Kommentar Headline (optional)"
                    value={commentHeadline}
                    onChange={(e) => setCommentHeadline(e.target.value)}
                    maxLength={maxHeadlineCharacters}
                />
                <span className={commentHeadline.length === maxHeadlineCharacters ? 'max-characters-reached' : ''}>
                    {commentHeadline.length}/{maxHeadlineCharacters} Zeichen
                </span>
            </Form.Group>
            <Form.Group as={Col} xs={12} controlId="commentContent" className='mt-3'>
                <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Gib einen Kommentar ab... (optional)"
                    value={commentContent}
                    onChange={(e) => setCommentContent(e.target.value)}
                    maxLength={maxContentCharacters}
                />
                <span className={commentContent.length === maxContentCharacters ? 'max-characters-reached' : ''}>
                    {commentContent.length}/{maxContentCharacters} Zeichen
                </span>
            </Form.Group>
        </div>
    );

}

export default RateComponent
