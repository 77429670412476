import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import './NavbarComponent.scss';
import { GoogleLogin } from '@react-oauth/google';
import { sendGoogleLoginCredentials } from '../../api/apiService';
import { User } from '../../models/user';
import { useLogin } from '../../contexts/LoginContext';
import LiveSearch from "../SearchComponent/LiveSearch";
import { Store } from "../../models/store";
import useSearch from "../SearchComponent/useSearch";
import { BACKEND_URL } from '../../api/apiConstants';

type SearchResult = Store | User;

const NavbarComponent: React.FC = () => {
    const { results, searchType, setSearchType, searchQuery, handleSearchChange } = useSearch('stores');
    const { user, login, logout } = useLogin();

    const handleGoogleLoginCredentialsSubmit = async (credentials: any) => {
        try {
            const mappedUser: User = await sendGoogleLoginCredentials(credentials);
            login(mappedUser);
        } catch (error) {
            console.error('error when sending credentials:', error);
        }
    }

    const renderSearchItem = (item: SearchResult) => {
        if ('city' in item) {
            return (
              <a className="search-item" href={`/store/${item.id}`}>
                  <img src={`${BACKEND_URL}/${item.image_path}`} alt={"NA"} />
                  <div className="item-info">
                      <span className="item-name">{item.name}</span>
                      <span className="item-city">{item.city}</span>
                  </div>
              </a>
            );
        } else {
            return (
              <a className="search-item" href={`/user/${item.id}`}>
                  <img src={`${BACKEND_URL}/${item.picture}`} alt={"NA"} />
                  <div className="item-info">
                      <span className="item-name">{item.username}</span>
                  </div>
              </a>
            );
        }
    };

    const handleSearchTypeChange = (type: 'stores' | 'users') => {
        setSearchType(type);
    };

    return (
      <Navbar fixed="top" expand="lg" className="navbar-container">
          <Container className='text-text'>
              <Navbar.Brand className='text-text' href="/">
                  <img src={'/favicon.ico'} alt="Favicon" width="40" height="40" />
              </Navbar.Brand>
              <div className="d-flex d-lg-none flex-grow-1 justify-content-center order-lg-2">
                  <div className="search-container-mobile d-flex">
                      <LiveSearch<SearchResult>
                        results={results}
                        value={searchQuery}
                        renderItem={renderSearchItem}
                        onChange={handleSearchChange}
                        inputClassName="search-input"
                        resultsClassName="search-results-mobile"
                        searchType={searchType}
                      />
                      <Dropdown onSelect={(eventKey: any) => handleSearchTypeChange(eventKey)}
                                className="search-type-dropdown-mobile">
                          <Dropdown.Toggle variant="success" id="dropdown-basic-mobile">
                              {searchType === 'stores' ? 'Läden' : 'Nutzer'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                              <Dropdown.Item eventKey="stores">Läden</Dropdown.Item>
                              <Dropdown.Item eventKey="users">Nutzer</Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>
                  </div>
              </div>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              {user !== null && (
                <a href="/profile" className="d-lg-none ml-2">
                    <img className="nav-profile-picture" src={`${BACKEND_URL}/${user.picture}`} />
                </a>
              )}
              <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                      <Nav.Link className='text-text' href="/">Home</Nav.Link>
                      <Nav.Link className='text-text' href="/stores">Dönerläden</Nav.Link>
                      {user?.role === "ADMIN" && (
                          <Nav.Link className='text-text' href="/store-management">Store Management</Nav.Link>
                      )}
                      {user !== null && (
                        <Nav.Link className='text-text logout-button-mobile d-lg-none' onClick={logout}>
                            <i className="bi bi-box-arrow-in-right"></i> Logout
                        </Nav.Link>
                      )}
                  </Nav>
                  <div className="d-none d-lg-flex search-container">
                      <div className="search-field-container">
                          <LiveSearch<SearchResult>
                            results={results}
                            value={searchQuery}
                            renderItem={renderSearchItem}
                            onChange={handleSearchChange}
                            inputClassName="search-input"
                            resultsClassName="search-results"
                            searchType={searchType}
                          />
                          <Dropdown onSelect={(eventKey: any) => handleSearchTypeChange(eventKey)}
                                    className="search-type-dropdown">
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  {searchType === 'stores' ? 'Läden' : 'Nutzer'}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                  <Dropdown.Item eventKey="stores">Läden</Dropdown.Item>
                                  <Dropdown.Item eventKey="users">Nutzer</Dropdown.Item>
                              </Dropdown.Menu>
                          </Dropdown>
                      </div>
                  </div>
                  {user === null ? (
                    <GoogleLogin
                      onSuccess={credentialResponse => {
                          console.log(credentialResponse);
                          handleGoogleLoginCredentialsSubmit(credentialResponse);
                      }}
                      onError={() => {
                          console.log('Login Failed');
                      }}
                    />
                  ) : (
                    <>
                        <a href="/profile" className="d-none d-lg-block">
                            <img className="nav-profile-picture" src={`${BACKEND_URL}/${user.picture}`} />
                        </a>
                        <div onClick={logout} className="logout-button d-none d-lg-block">
                            <i className="bi bi-box-arrow-in-right"></i>
                        </div>
                    </>
                  )}
              </Navbar.Collapse>
          </Container>
      </Navbar>
    );
};

export default NavbarComponent;
