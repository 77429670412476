enum Ranks {
  DueruemDiener = 0,
  RotkrautRookie = 10,
  ZwiebelZorro = 20,
  FalafelFuerst = 30,
  FladenbrotFanatiker = 40,
  HackspiessHaeuptling = 50,
  TsatsikiTyrann = 65,
  AyranAdmiral = 80,
  BaklavaBaron = 100,
  PidePrinz = 120,
  KebapKoenig = 150,
  LavashLord = 180,
  KebapKaiser = 220,
  KebapKoriphaee = 300,
  DoenerDominator = 400,
  DerGeraet = 500
}

const rankNames: { [key: number]: string } = {
  [Ranks.DueruemDiener]: '1 - Dürüm-Diener',
  [Ranks.RotkrautRookie]: '2 - Rotkraut-Rookie',
  [Ranks.ZwiebelZorro]: '3 - Zwiebel-Zorro',
  [Ranks.FalafelFuerst]: '4 - Falafel-Fürst',
  [Ranks.FladenbrotFanatiker]: '5 - Fladenbrot-Fanatiker',
  [Ranks.HackspiessHaeuptling]: '6 - Hackspieß-Häuptling',
  [Ranks.TsatsikiTyrann]: '7 - Tsatsiki-Tyrann',
  [Ranks.AyranAdmiral]: '8 - Ayran-Admiral',
  [Ranks.BaklavaBaron]: '9 - Baklava-Baron',
  [Ranks.PidePrinz]: '10 - Pide-Prinz',
  [Ranks.KebapKoenig]: '11 - Kebap-König',
  [Ranks.LavashLord]: '12 - Lavash-Lord',
  [Ranks.KebapKaiser]: '13 - Kebap-Kaiser',
  [Ranks.KebapKoriphaee]: '14 - Kebap-Koryphäe',
  [Ranks.DoenerDominator]: '15 - Döner-Dominator',
  [Ranks.DerGeraet]: 'Der Gerät'
};

class RankUtils {
  static getRank(points: number): string {
    let rank = Ranks.DueruemDiener;
    for (const value of Object.values(Ranks).filter(v => typeof v === 'number') as number[]) {
      if (points >= value) {
        rank = value;
      } else {
        break;
      }
    }
    return rankNames[rank];
  }
}

export { Ranks, RankUtils };
