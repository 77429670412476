import React, {useEffect, useState} from 'react';
import {Button, Col, Container, FloatingLabel, Row} from 'react-bootstrap';
import RatingProgressBar from './RatingsComponent/RatingProgressBar';
import CommentSectionComponent from './CommentSectionComponent/CommentSectionComponent';
import './StoreComponent.scss';
import RatingBadgeComponent from './CommentSectionComponent/CommentRatingComponent';
import SidebarComponent from './SidebarComponent/SidebarComponent';
import {useNavigate, useParams} from 'react-router-dom';
import {Store} from '../../models/store';
import {
    acceptStoreById,
    fetchStoreById,
    getExistingRatingAndComment, reportStoreById,
    submitRating,
    submitRatingComment
} from '../../api/apiService';
import {CONSTANTS} from '../../constants';
import RateComponent from './RateComponent/RateComponent';
import {Rating} from '../../models/rating';
import {Comment} from '../../models/comment';
import {useLogin} from '../../contexts/LoginContext';
import Modal from 'react-bootstrap/Modal';
import {BACKEND_URL} from '../../api/apiConstants';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

const StoreComponent: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [store, setStore] = useState<Store>();
    const [isRating, setIsRating] = useState<boolean>(false);
    const [breadRating, setBreadRating] = useState<number>(0);
    const [vegetablesRating, setVegetablesRating] = useState<number>(0);
    const [sauceRating, setSauceRating] = useState<number>(0);
    const [meatRating, setMeatRating] = useState<number>(0);
    const [pricePerformanceRating, setPricePerformanceRating] = useState<number>(0);
    const [commentHeadline, setCommentHeadline] = useState('');
    const [commentContent, setCommentContent] = useState('');
    const [existingRating, setExistingRating] = useState<Rating | null>(null);
    const [existingComment, setExistingComment] = useState<Comment | null>(null);
    const [showNotLoggedInModal, setShowNotLoggedInModal] = useState(false);
    const [refreshComments, setRefreshComments] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [storeReported, setStoreReported] = useState(false);

    const {user} = useLogin();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }, []);

    useEffect(() => {
        if (store?.status === "IN_PROGRESS" || store?.status === "REPORTED") {
            if (user?.role !== "ADMIN") {
                navigate('/stores');
            }
        }
    }, [store, user?.role, navigate]);


    // The whole Modal construct should later one be outsourced to an own component, receiving the shown string as a param
    const handleClose = () => {
        setShowNotLoggedInModal(false);
    }

    const fetchStoreData = async () => {
        let storeId: number | null = null;
        try {
            if (id) {
                storeId = parseInt(id, 10);
                const mappedStore: Store = await fetchStoreById(storeId);
                setStore(mappedStore);
            }
            if (user && storeId) {
                const {rating, comment} = await getExistingRatingAndComment(user.id, storeId);

                if (rating) {
                    setExistingRating(rating);
                }
                if (comment) {
                    setExistingComment(comment);
                }
            }
        } catch (error) {
            console.error('error fetch data in StoreComponent', error);
        }
    };

    useEffect(() => {
        fetchStoreData();
    }, [id]);


    const ratingChangedCallback = (
        breadRating: number,
        vegetablesRating: number,
        sauceRating: number,
        meatRating: number,
        pricePerformanceRating: number,
        commentHeadline: string,
        commentContent: string
    ) => {
        setBreadRating(breadRating);
        setVegetablesRating(vegetablesRating);
        setSauceRating(sauceRating);
        setMeatRating(meatRating);
        setPricePerformanceRating(pricePerformanceRating);
        setCommentHeadline(commentHeadline);
        setCommentContent(commentContent);
    };

    const handleSubmit = async () => {
        try {
            let commentId = null;

            if (!user || !store) {
                console.error('User or store information is not available.');
                return;
            }

            const updatedCommentFormData = {
                user_id: user.id,
                store_id: store.id,
                upvotes: 0,
                reply_to: null,
                comment_headline: commentHeadline,
                comment_content: commentContent
            };

            const commentResponse = await submitRatingComment(updatedCommentFormData);

            if (commentResponse && commentResponse.status === 'success') {
                commentId = commentResponse.comment_id;
                console.log('Comment submitted with ID:', commentId);
            } else {
                console.error('Comment submission failed:', commentResponse ? commentResponse.message : 'Unknown error');
                return;
            }

            const ratingFormData = {
                user_id: user.id,
                vegetable: vegetablesRating,
                meat: meatRating,
                bread: breadRating,
                sauce: sauceRating,
                price_performance: pricePerformanceRating,
                store_id: store.id,
                comment_id: commentId,
            };

            const ratingResponse = await submitRating(ratingFormData);

            if (ratingResponse) {
                await fetchStoreData();
                console.log('Rating submitted');
                setRefreshComments(true);
            } else {
                console.error('Rating submission failed.');
            }
        } catch (error) {
            console.error('Error when submitting comment and rating:', error);
        }
    };

    const generateGoogleMapsLink = (store: Store): string => {
        const { name, street, house_nr, zip, city } = store;
        const address = `${name}, ${street} ${house_nr}, ${zip} ${city}`;
        return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    };

    const handleMapsClick = () => {
        if (store) {
            const mapsLink = generateGoogleMapsLink(store);
            window.open(mapsLink, '_blank');
        }
    };

    const handleCloseReportModal = (redirect: boolean) => {
        setShowReportModal(false);
        setStoreReported(false);
        if (redirect) {
            navigate('/stores');
        }
    }

    const handleShowReportModal = () => {
        setShowReportModal(true);
    }

    const handleReportStore = () => {
        setStoreReported(true)
        reportStore();
    }

    const reportStore = async () => {
        let storeId: number | null = null;
        try {
            if (id) {
                storeId = parseInt(id, 10);
                await reportStoreById(storeId);
            }
        } catch (error) {
            console.error('error report store by id', error);
        }
    };

    const areAllRatingsZero = () => {
        return breadRating === 0 && vegetablesRating === 0 && sauceRating === 0 && meatRating === 0 && pricePerformanceRating === 0;
    };

    return (
          <>
            <div className='body-wrapper'>
                <div className='page-holder'>
                    <div className='main'>
                        <Container>
                            <Row>
                                <Row>
                                    <Col md={4}>
                                        <div className='image-holder'>
                                            <img
                                                className="store-image"
                                                src={`${BACKEND_URL}/${store?.image_path}`}
                                                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                    const target = e.target as HTMLImageElement;
                                                    target.src = 'https://placehold.co/600x400?text=No+Image';
                                                }}
                                                width="100%"
                                                height="100%"
                                            />
                                            <Button className="mt-3 mb-5 text-white" variant="grey" size="lg" onClick={handleMapsClick}>
                                                <i className="bi bi-search"> </i>
                                                Auf Maps ansehen
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col md={8}>
                                        <div className='store-header-container'>
                                            <div className='address-container'>
                                                <div className='store-headline-container'>
                                                    <h1>{store?.name}</h1>
                                                    <Dropdown>
                                                        <Dropdown.Toggle as="i" className="bi bi-three-dots-vertical fw-bold edit-store-icon" />
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={handleShowReportModal}><i className="bi bi-flag-fill"></i>&nbsp;&nbsp;Melden</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                                <p className='secondary-text'>{store?.zip} {store?.city}</p>
                                                <p className='secondary-text'>{store?.street} {store?.house_nr}</p>
                                            </div>
                                            <div className='total-top-rating-container'>
                                                <RatingBadgeComponent rating={store?.rating_average ?? 0}
                                                                      bgColor="secondary" text={''}
                                                                      badgeSize='large'></RatingBadgeComponent>
                                                <p>/10</p>
                                                <p>{store?.rating_amount} Bewertung(en)</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                    </Col>
                                    <Col md={8} className='rating-container'>
                                        <h4 className='mb-4'>Bewertungen</h4>
                                        <div className='bar-container'>
                                            {isRating ? (
                                                <>
                                                    <RateComponent ratingChangedCallback={ratingChangedCallback}
                                                                   existingRating={existingRating}
                                                                   existingComment={existingComment}></RateComponent>
                                                </>
                                            ) : (
                                                <>
                                                    <RatingProgressBar title={CONSTANTS.BREAD} color='bread'
                                                                       rating={store?.bread_average ?? 0}/>
                                                    <RatingProgressBar title={CONSTANTS.MEAT} color="meat"
                                                                       rating={store?.meat_average ?? 0}/>
                                                    <RatingProgressBar title={CONSTANTS.VEGETABLES} color="vegetable"
                                                                       rating={store?.vegetable_average ?? 0}/>
                                                    <RatingProgressBar title={CONSTANTS.SAUCE} color="sauce"
                                                                       rating={store?.sauce_average ?? 0}/>
                                                    <RatingProgressBar title={CONSTANTS.TOTAL} color="secondary"
                                                                       rating={store?.rating_average ?? 0}/>
                                                    <RatingProgressBar title={CONSTANTS.PRICE_PERFORMANCE}
                                                                       color="price-performance"
                                                                       rating={store?.price_performance_average ?? 0}/>
                                                </>
                                            )}
                                        </div>
                                        <Button className="mt-3 text-white"
                                                variant={isRating ? "secondary" : "grey"}
                                                onClick={() => {
                                                    if (user === null) {
                                                        setShowNotLoggedInModal(true); // Zeige das Modal an, wenn der Benutzer nicht eingeloggt ist
                                                    } else {
                                                        setIsRating(!isRating);
                                                        if (isRating && !areAllRatingsZero()) {
                                                            handleSubmit();
                                                        }
                                                    }
                                                }}
                                                disabled={isRating && areAllRatingsZero()}
                                        >
                                            {isRating ? 'Absenden' : <><i className="bi bi-star-half"></i> {existingRating ? 'Bearbeiten' : 'Bewerten'}</>}
                                        </Button>
                                        {isRating && (
                                            <Button className="mt-3 ms-2 text-white" variant="grey"
                                                    onClick={() => setIsRating(false)}>
                                                Abbrechen
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="row-margin">
                                    <CommentSectionComponent storeId={id ? id : null}
                                                             refresh={refreshComments}></CommentSectionComponent>
                                </Row>
                            </Row>
                        </Container>
                    </div>
                    <div className='sidebar'>
                        <SidebarComponent zipCode={store?.zip ?? "0"} storeId={store?.id ?? 0}></SidebarComponent>
                    </div>
                </div>
                <Modal centered show={showNotLoggedInModal} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Sie müssen sich zuerst einloggen, um einen Store bewerten zu können.</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Schließen
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <Modal centered show={showReportModal} onHide={() => handleCloseReportModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Diesen Laden melden</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { storeReported && (
                      <Row>
                          <Col xs={12}>
                              Sie haben den Laden erfolgreich gemeldet, dieser wird nun nicht mehr angezeigt und von einem Administrator geprüft.
                          </Col>
                      </Row>
                    )}
                    { !storeReported && (
                      <div>
                          Möchten Sie diesen Laden wirklich melden? Wenn Sie einen Urheberrechtsverstoß vermuten, können Sie dies hier melden.
                      </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {!storeReported && (
                      <>
                        <Button variant="primary" onClick={() => handleCloseReportModal(false)}>
                            Nein
                        </Button>
                        <Button variant="secondary" onClick={handleReportStore}>
                            Ja
                        </Button>
                      </>
                    )}
                    {storeReported && (
                      <Button variant="primary" onClick={() => handleCloseReportModal(true)}>Schließen</Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default StoreComponent;
