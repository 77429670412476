import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { searchStores, searchUsers } from '../../api/apiService';
import { Store } from '../../models/store';
import { User } from '../../models/user';
import { useNavigate, useLocation } from 'react-router-dom';

type SearchResult = Store | User;

const useSearch = (initialSearchType: 'stores' | 'users') => {
    const [results, setResults] = useState<SearchResult[]>([]);
    const [searchType, setSearchType] = useState<'stores' | 'users'>(initialSearchType);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const fetchResults = async (query: string) => {
        try {
            if (searchType === 'stores') {
                const stores = await searchStores(query);
                setResults(stores);
            } else {
                const users = await searchUsers(query);
                setResults(users);
            }
        } catch (error) {
            console.error('Search error:', error);
        }
    };

    const debouncedFetchResults = useCallback(debounce(fetchResults, 300), [searchType]);

    // Load initial search query from URL parameters
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const initialQuery = searchParams.get('query') || '';
        setSearchQuery(initialQuery);
        if (initialQuery) {
            fetchResults(initialQuery);
        }
    }, [location.search, searchType]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setSearchQuery(newValue);
        debouncedFetchResults(newValue);

        // Update the query parameter in the URL
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('query', newValue);
        navigate(`?${searchParams.toString()}`, { replace: true });
    };

    return {
        results,
        searchType,
        setSearchType,
        searchQuery,
        handleSearchChange,
    };
};

export default useSearch;
