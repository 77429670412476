import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './StoresOverviewComponent.scss';
import { Store } from '../../models/store';
import { fetchStores, searchStores } from '../../api/apiService';
import StoreItem from './StoreItemComponent/StoreItemComponent';
import StoreOverviewFilterBarComponent from './StoreOverviewFilterComponent/StoreOverviewFilterBarComponent';
import StoreOverviewFilterComponent from './StoreOverviewFilterComponent/StoreOverviewFilterComponent';
import { useNavigate, useLocation } from 'react-router-dom';

const StoresOverviewComponent: React.FC = () => {
  const [stores, setStores] = useState<Store[] | null>(null);
  const [shownStores, setShownStores] = useState<Store[] | null>(null);
  const [currentFilter, setCurrentFilter] = useState<string>('new');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 16;
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = useState<string>('');

  // Nur bei initialem Laden oder bei manueller Aktion durch Benutzer
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page') || '1', 10);
    const filter = searchParams.get('filter') || 'new';
    const queryParam = searchParams.get('query') || '';  // Query-Parameter aus der URL extrahieren

    setCurrentPage(page);
    setCurrentFilter(filter);
    setQuery(queryParam)
    // Fetch stores only when the component first loads or the user explicitly triggers it
    if (queryParam) {
      fetchStoresData(queryParam);
    } else {
      fetchStoresData();  // fetch all stores if no query
    }
  }, []);

  // Wende Filter an, wenn sich die Stores oder der Filter ändern
  useEffect(() => {
    if (stores) {
      applyFilter(currentFilter);
    }
  }, [stores, currentFilter]);

  const applyFilter = (filter: string, direction?: boolean) => {
    let filteredStores: Store[];

    if (!stores) return;

    switch (filter) {
      case 'new':
        filteredStores = [...stores].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        break;
      case 'best':
        filteredStores = [...stores].sort((a, b) => b.rating_average - a.rating_average);
        break;
      case 'worst':
        filteredStores = [...stores].filter(store => store.rating_amount > 0).sort((a, b) => a.rating_average - b.rating_average);
        break;
      case 'alphabetical':
        if (direction) {
          filteredStores = [...stores].sort((a, b) => a.name.localeCompare(b.name));
        } else {
          filteredStores = [...stores].sort((a, b) => b.name.localeCompare(a.name));
        }
        break;
      case 'ratingAmount':
        filteredStores = [...stores].sort((a, b) => b.rating_amount - a.rating_amount);
        break;
      default:
        filteredStores = [...stores];
        break;
    }

    setShownStores(filteredStores);
  };

  const handleFilterChange = (
    zipCode: string,
    total: number,
    bread: number,
    vegetables: number,
    sauce: number,
    meat: number,
    pricePerformance: number
  ) => {
    if (!stores) return;

    const filteredStores = stores.filter(store => {
      return (
        (zipCode === '' || store.zip === zipCode) &&
        (total === 0 || store.rating_average >= total) &&
        (bread === 0 || store.bread_average >= bread) &&
        (vegetables === 0 || store.vegetable_average >= vegetables) &&
        (sauce === 0 || store.sauce_average >= sauce) &&
        (meat === 0 || store.meat_average >= meat) &&
        (pricePerformance === 0 || store.price_performance_average >= pricePerformance)
      );
    });

    setShownStores(filteredStores);
  };

  const fetchStoresData = async (queryParam?: string) => {
    let fetchedStores: Store[] = [];

    fetchedStores = await fetchStores();

    if (queryParam) {
      fetchedStores = fetchedStores.filter(store =>
        store.name.toLowerCase().includes(queryParam.toLowerCase()) ||
        store.city.toLowerCase().includes(queryParam.toLowerCase()) ||
        store.zip.includes(queryParam)
      );

      fetchedStores = fetchedStores.sort((a, b) => {
        const aNameStartsWith = a.name.toLowerCase().startsWith(query);
        const bNameStartsWith = b.name.toLowerCase().startsWith(query);

        const aCityStartsWith = a.city.toLowerCase().startsWith(query);
        const bCityStartsWith = b.city.toLowerCase().startsWith(query);

        const aNameIncludes = a.name.toLowerCase().includes(query);
        const bNameIncludes = b.name.toLowerCase().includes(query);

        const aCityIncludes = a.city.toLowerCase().includes(query);
        const bCityIncludes = b.city.toLowerCase().includes(query);

        if (aNameStartsWith && !bNameStartsWith) return -1;
        if (!aNameStartsWith && bNameStartsWith) return 1;

        if (aNameIncludes && bNameIncludes) {
          return a.name.localeCompare(b.name);
        }

        if (aCityStartsWith && !bCityStartsWith) return -1;
        if (!aCityStartsWith && bCityStartsWith) return 1;

        if (aCityIncludes && bCityIncludes) {
          return a.city.localeCompare(b.city);
        }

        return 0;
      });
    }

    setStores(fetchedStores);
    setShownStores(fetchedStores);
  };

  const handleStoreItemClick = (storeId: number) => {
    sessionStorage.setItem('scrollPosition', window.scrollY.toString());
    navigate(`/store/${storeId}`);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', pageNumber.toString());
    searchParams.set('filter', currentFilter);
    navigate(`?${searchParams.toString()}`, { replace: true });
    window.scrollTo(0, 0);
  };

  const handleTopBarFilterChange = (filter: string, direction?: boolean) => {
    setCurrentFilter(filter);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('filter', filter);
    searchParams.set('page', '1');
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const paginatedStores = shownStores?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const totalPages = Math.ceil((shownStores?.length || 0) / itemsPerPage);

  return (
    <div className="page-holder">
      <div className="body-wrapper">
        <Container>
          {query && <h2>Suchergebnisse für "{query}"</h2>}

          {!query && ( // Nur anzeigen, wenn keine Query vorhanden ist
            <>
              <Row>
                <StoreOverviewFilterBarComponent
                  onFilterChange={handleTopBarFilterChange}
                  currentFilter={currentFilter}
                  setCurrentPage={setCurrentPage}
                />
              </Row>
              <Row className="mt-2">
                <StoreOverviewFilterComponent
                  onFilterChange={handleFilterChange}
                  onAddStore={() => fetchStoresData()}
                />
              </Row>
            </>
          )}

          <Row className="mt-3 justify-content-start">
            {paginatedStores &&
              paginatedStores.map(item => (
                <Col
                  key={item.id}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="mb-3 justify-content-center d-flex"
                >
                  <StoreItem item={item} onItemClick={handleStoreItemClick} />
                </Col>
              ))}
          </Row>
          <Row className="mt-3 justify-content-center">
            <div className="pagination-controls">
              <Button
                variant="primary"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Vorherige
              </Button>
              <span className="page-number">
                {currentPage} / {totalPages}
              </span>
              <Button
                variant="primary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Nächste
              </Button>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default StoresOverviewComponent;
