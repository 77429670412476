// models.ts

export class Store {
    id: number;
    name: string;
    rating_amount: number;
    rating_average: number;
    vegetable_average: number;
    meat_average: number;
    bread_average: number;
    sauce_average: number;
    price_performance_average: number;
    created_at: Date;
    street: string;
    house_nr: string;
    zip: string;
    city: string;
    image_path: string;
    user_id: number;
    status: string;

    constructor(id: number, name: string, rating_amount: number, rating_average: number, vegetable_average: number, 
        meat_average: number, bread_average: number,  sauce_average: number, price_performance_average: number,
                created_at: Date, street: string, house_nr: string, zip: string, city: string, image_path: string,
                user_id: number, status:string) {
        this.id = id;
        this.name = name;
        this.rating_amount = rating_amount;
        this.rating_average = rating_average;
        this.vegetable_average = vegetable_average;
        this.meat_average = meat_average;
        this.bread_average = bread_average;
        this.sauce_average = sauce_average;
        this.price_performance_average = price_performance_average;
        this.created_at = created_at;
        this.street = street;
        this.house_nr = house_nr;
        this.zip = zip;
        this.city = city;
        this.image_path = image_path;
        this.user_id = user_id;
        this.status = status;
    }
}
