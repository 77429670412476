import React, {useEffect, useState} from 'react';
import './HomeComponent.scss';
import {Store} from '../../models/store';
import {fetchTopTenStores} from '../../api/apiService';
import StoreItem from '../StoresComponent/StoreItemComponent/StoreItemComponent';
import {useNavigate} from 'react-router-dom';
import {Row, Col, Container} from 'react-bootstrap';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import {BACKEND_URL} from "../../api/apiConstants";
import {Typography} from "@mui/material";

const HomeComponent = () => {
    const [topTenStores, setTopTenStores] = useState<Store[] | null>(null);
    const [topThreeStores, setTopThreeStores] = useState<Store[] | null>(null);
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);


    useEffect(() => {
        fetchTopStores();
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchTopStores = async () => {
        try {
            const mappedStores: Store[] = await fetchTopTenStores();
            setTopTenStores(mappedStores.slice(3, 10));
            setTopThreeStores(mappedStores.slice(0, 3));
        } catch (error) {
            console.error('Error fetching data in HomeComponent', error);
        }
    };

    const handleStoreItemClick = (storeId: number) => {
        navigate(`/store/${storeId}`);
    };

    return (
        <div className='body-wrapper text-center'>
            <div className="spacer"></div>
            <div className="ranking-container">
                <Container>
                    {topThreeStores && (
                      <Row className="justify-content-center custom-padding-row">
                            <Col xs={12} className="store-rank rank-1">
                                <StoreItem item={topThreeStores[0]}
                                           onItemClick={() => handleStoreItemClick(topThreeStores[0].id)}/>
                                <div className="rank-label">#1</div>
                            </Col>
                        </Row>
                    )}
                    {topThreeStores && (
                      <Row className="justify-content-center custom-padding-row" style={{ gap: '12px', flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
                            <Col xs={12} sm={6} className="store-rank rank-2">
                                <StoreItem item={topThreeStores[1]}
                                           onItemClick={() => handleStoreItemClick(topThreeStores[1].id)}/>
                                <div className="rank-label">#2</div>
                            </Col>
                            <Col xs={12} sm={6} className="store-rank rank-3">
                                <StoreItem item={topThreeStores[2]}
                                           onItemClick={() => handleStoreItemClick(topThreeStores[2].id)}/>
                                <div className="rank-label">#3</div>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
            <div className="top-ten-container">
                {isMobile ? (
                    <List>
                        {topTenStores ? (
                            topTenStores.map((store, index) => (
                                <ListItem key={store.id} button onClick={() => handleStoreItemClick(store.id)} className={`store-rank-top-10`}>
                                    <ListItemAvatar>
                                        <div className="avatar-wrapper">
                                            <Avatar alt={store.name} src={`${BACKEND_URL}/${store.image_path}`} />
                                            <span className="rank-label-top-10">{index + 4}</span>
                                        </div>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <>
                                                <Typography variant="body1" component="span"
                                                            style={{fontWeight: 'bold'}}>
                                                    {store.name}
                                                </Typography>
                                                <br/>
                                                <Typography variant="body2" component="span">
                                                    {store.city}
                                                </Typography>
                                            </>
                                        }
                                    />
                                </ListItem>
                            ))
                        ) : (
                            <></>
                        )}
                    </List>
                ) : (
                    <Row className="top-ten-stores justify-content-center">
                        {topTenStores ? (
                            topTenStores.map((store, index) => (
                              <Col key={store.id} xs={12} sm={6} md={4} lg={3} className="mb-4">
                                  <div className="store-rank-top-10-desktop">
                                      <StoreItem item={store} onItemClick={() => handleStoreItemClick(store.id)}/>
                                      <span className="rank-label-top-10">{index + 4}</span>
                                  </div>
                              </Col>
                            ))
                        ) : (
                            <></>
                        )}
                    </Row>
                )}
            </div>
        </div>
    );
};

export default HomeComponent;
