import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Form, InputGroup, Modal, Row} from 'react-bootstrap';
import './ProfileComponent.scss';
import {useLogin} from '../../contexts/LoginContext';
import {useNavigate} from 'react-router-dom';
import {
    changeProfilePicture,
    changeUsername,
    deleteOwnProfile,
    fetchRatingsByUserId,
    fetchStoreById,
    fetchTotalUpvotesByUserId, fetchTotalUpvotesGivenByUserId
} from '../../api/apiService';
import {Rating} from "../../models/rating";
import {Store} from "../../models/store";
import RatingsTable from "./RatingsTableComponent";
import {BACKEND_URL} from '../../api/apiConstants';
import StoreItem from "../StoresComponent/StoreItemComponent/StoreItemComponent";
import {RankUtils} from "../../utils/Ranks";

const ProfileComponent = ({ }) => {
    const { user, login, logout } = useLogin();
    const navigate = useNavigate();
    const [editingUsername, setEditingUsername] = useState(false);
    const [newUsername, setNewUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [ratingAmount, setRatingAmount] = useState<number>(0);
    const [upvoteCount, setUpvoteCount] = useState<number>(0);
    const [upvotesGiven, setUpvotesGiven] = useState<number>(0);
    const [topStores, setTopStores] = useState<{ store: Store, average: number }[]>([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        if (!user) {
            navigate('/');
        }
        fetchRatings();
        fetchUpvoteCount();
        fetchUpvotesGivenCount()
    }, [user]);

    const fetchRatings = async () => {
        try {
            if (user) {
                const mappedRatings: Rating[] = await fetchRatingsByUserId(user.id);
                setRatingAmount(mappedRatings.length);
                const topThreeRatedStores = await getTopThreeRatedStores(mappedRatings);
                setTopStores(topThreeRatedStores)

            }
        } catch (error) {
            console.error('error fetch data in ProfileComponent', error);
        }
    };

    const fetchUpvoteCount = async () => {
        try {
            if (user) {
                const totalUpvoteCount = await fetchTotalUpvotesByUserId(user.id);
                setUpvoteCount(totalUpvoteCount);
            }
        } catch (error) {
            console.error('Error fetching data in ProfileComponent:', error);
        }
    };

    const fetchUpvotesGivenCount = async () => {
        try {
            if (user) {
                const totalUpvotesGiven = await fetchTotalUpvotesGivenByUserId(user.id);
                setUpvotesGiven(totalUpvotesGiven);
            }
        } catch (error) {
            console.error('Error fetching data in ProfileComponent:', error);
        }
    };

    const calculateAverageRating = (rating: Rating) => {
        // Sammle alle Bewertungen in einem Array
        const values = [rating.meat, rating.bread, rating.vegetable, rating.sauce];

        // Filtere die Bewertungen heraus, die 0 sind
        const nonZeroValues = values.filter(value => value !== 0);

        // Wenn alle Werte 0 sind, gib 0 als Durchschnitt zurück
        if (nonZeroValues.length === 0) {
            return 0;
        }

        // Berechne den Durchschnitt der verbleibenden Werte
        const average = nonZeroValues.reduce((acc, value) => acc + value, 0) / nonZeroValues.length;

        // Runde den Durchschnitt auf eine Dezimalstelle
        return Math.round(average * 10) / 10;
    };

    const getTopThreeRatedStores = async (ratings: Rating[]): Promise<{ store: Store, average: number }[]> => {
        const topRated = ratings
            .map(rating => ({
                store_id: rating.store_id,
                average: calculateAverageRating(rating)
            }))
            .sort((a, b) => b.average - a.average)
            .slice(0, 3);

        const topRatedStores = await Promise.all(topRated.map(async ({ store_id, average }) => {
            const store = await fetchStoreById(store_id);
            return { store, average };
        }));

        return topRatedStores;
    };

    const handleEditClick = () => {
        setEditingUsername(true);
        setNewUsername(user?.username || '');
    };

    const handleSaveClick = () => {
        // remove leading whitespaces
        const trimmedUsername = newUsername.trim();

        // check for whitespaces
        if (trimmedUsername.includes(' ')) {
            setUsernameError('Username cannot contain spaces');
            // Du könntest hier auch eine Benachrichtigung für den Benutzer anzeigen
            return;
        }

        // Überprüfe, ob der Benutzername Sonderzeichen enthält
        const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
        if (specialCharsRegex.test(trimmedUsername)) {
            setUsernameError('Username cannot contain special characters');
            // Du könntest hier auch eine Benachrichtigung für den Benutzer anzeigen
            return;
        }

        // Save newUsername because name is valid
        setEditingUsername(false);
        setUsernameError('');
        handleSubmitNewUsername();
    };

    const handleSubmitNewUsername = async () => {
        try {
            if (user) {
                await changeUsername(newUsername);
                setUsernameError('');
                console.log('new username submitted');
                login({ ...user, username: newUsername });
            } else {
                console.error('user.id is undefined or null');
            }
        } catch (error) {
            console.error('error when submitting new username:', error);
            setUsernameError('Der Benutzername existiert bereits');
        }
    };

    const handleCancelClick = () => {
        setEditingUsername(false);
        setUsernameError('')
    };

    const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewUsername(event.target.value);
    };

    const handleCardClick = (storeId: number) => {
        navigate(`/store/${storeId}`);
    };

    const handleProfilePictureChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && user) {
            try {
                const updatedUser = await changeProfilePicture(user.id, file);
                console.log(updatedUser);
                console.log(updatedUser.picture);
                login({ ...user, picture: updatedUser.picture });
            } catch (error) {
                console.error('Error updating profile picture:', error);
            }
        }
    };

    const handleDeleteAccount = async () => {
        try {
            if (user) {
                await deleteOwnProfile();
                console.log('Account deleted');
                logout();
                navigate('/');
            }
        } catch (error) {
            console.error('Error deleting account:', error);
        }
    };

    const formatDate = (dateString: Date) => {
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

    return (
        <div className='page-holder'>
            <div className='body-wrapper'>
                <Row>
                    <Col lg={3} md={6} xs={12} className="position-relative">
                        <div className='profile-picture-container'>
                            <img className='profile-picture' src={`${BACKEND_URL}/${user?.picture}`} alt="Profile" />
                            <div className='profile-picture-hover' onClick={() => document.getElementById('profile-picture-input')?.click()}>
                                <i className="bi bi-pencil-fill"></i>
                            </div>
                            <input
                                type="file"
                                id="profile-picture-input"
                                style={{ display: 'none' }}
                                accept="image/*"
                                onChange={handleProfilePictureChange}
                            />
                        </div>
                    </Col>
                    <Col lg={9} md={6}>
                        <div className='user-container'>
                            <div className="username">
                                {editingUsername ? (
                                  <InputGroup className="mb-3 username">
                                      <Col sm={12} xl={3}>
                                          <Form.Control
                                            type="text"
                                            value={newUsername}
                                            onChange={handleChangeUsername}
                                            placeholder="Username"
                                          />
                                      </Col>
                                      <Button className='save-username-button' variant='secondary' size='sm'
                                              onClick={handleSaveClick}>Save</Button>
                                      <Button variant='primary' size='sm' onClick={handleCancelClick}>Cancel</Button>
                                  </InputGroup>
                                ) : (
                                  <>
                                      <h3 onClick={handleEditClick} style={{cursor: 'pointer'}}><i
                                        className="bi bi-person-fill"></i> {user?.username}{' '}</h3>
                                  </>
                                )}
                                {usernameError && <Alert variant='primary'>{usernameError}</Alert>}
                            </div>
                            <div className='user-info-container'>
                            <div>
                                    <i className="bi bi-trophy-fill"></i> <b>Rang:</b> {RankUtils.getRank(upvoteCount + upvotesGiven + (0.5 * ratingAmount))}
                                </div>
                            <div>
                                    <i className="bi bi-calendar-fill"></i> <b>Mitglied seit:</b> {user?.created_at ? formatDate(user.created_at) : ''}
                                </div>
                                <div>
                                    <i className="bi bi-star-fill"></i> <b>Bewertungen:</b> {ratingAmount}
                                </div>
                                <div>
                                    <i className="bi bi-hand-thumbs-up-fill"></i> <b>Erhaltene Upvotes:</b> {upvoteCount}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-5">
                    {topStores[0] && (
                        <Col className="top-store-box" md={4} xs={12}>
                            <div className="top-store-box text-center top-store-box profile-store-rank profile-rank-1">
                                <StoreItem item={topStores[0].store}
                                           onItemClick={() => handleCardClick(topStores[0].store.id)}
                                           alternativeRating={parseFloat(topStores[0].average.toFixed(2))}
                                />
                                <div className="profile-rank-label">#1</div>
                            </div>
                        </Col>
                    )}
                    {topStores[1] && (
                        <Col className="top-store-box" md={4} xs={12}>
                            <div className="top-store-box text-center top-store-box profile-store-rank profile-rank-2">
                                <StoreItem item={topStores[1].store}
                                           onItemClick={() => handleCardClick(topStores[1].store.id)}
                                           alternativeRating={parseFloat(topStores[1].average.toFixed(2))}
                                />
                                <div className="profile-rank-label">#2</div>
                            </div>
                        </Col>
                    )}
                    {topStores[2] && (
                        <Col className="top-store-box" md={4} xs={12}>
                            <div className="top-store-box text-center top-store-box profile-store-rank profile-rank-3">
                                <StoreItem item={topStores[2].store}
                                           onItemClick={() => handleCardClick(topStores[2].store.id)}
                                           alternativeRating={parseFloat(topStores[2].average.toFixed(2))}
                                />
                                <div className="profile-rank-label">#3</div>
                            </div>
                        </Col>
                    )}
                </Row>
                <Row>
                    <RatingsTable></RatingsTable>
                </Row>
                <Row className="mt-5 justify-content-center">
                    <Col xs="auto">
                        <Button variant="red" onClick={() => setShowDeleteModal(true)}>Meinen Account Löschen</Button>
                    </Col>
                </Row>
            </div>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Account löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Sind Sie sicher, dass Sie Ihren Account löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Abbrechen
                    </Button>
                    <Button variant="red" onClick={handleDeleteAccount}>
                        Account Löschen
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

}

export default ProfileComponent;
