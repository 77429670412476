const MEAT = 'Fleisch';
const BREAD = 'Brot';
const SAUCE = 'Soße';
const VEGETABLES = 'Gemüse';
const TOTAL = 'Gesamt';
const PRICE_PERFORMANCE = 'Preis-Leistung';
const PRICE_PERFORMANCE_SHORT = 'P/L'
const MEAT_MOBILE = '🍗';
const BREAD_MOBILE = '🍞';
const SAUCE_MOBILE = '🥛';
const VEGETABLES_MOBILE = '🥬';
const TOTAL_MOBILE = '🥙';
const PRICE_PERFORMANCE_MOBILE = '💵'

export const CONSTANTS = {
  MEAT,
  BREAD,
  SAUCE,
  VEGETABLES,
  TOTAL,
  PRICE_PERFORMANCE,
  PRICE_PERFORMANCE_MOBILE,
  PRICE_PERFORMANCE_SHORT,
  MEAT_MOBILE,
  BREAD_MOBILE,
  SAUCE_MOBILE,
  VEGETABLES_MOBILE,
  TOTAL_MOBILE,
};
