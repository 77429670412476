import React from 'react';
import Card from 'react-bootstrap/Card';
import RatingBadgeComponent from '../CommentSectionComponent/CommentRatingComponent';
import './SidebarStoreItemComponent.scss';
import {Store} from '../../../models/store';
import {BACKEND_URL} from '../../../api/apiConstants';

interface SidebarStoreItemProps {
    item: Store;
    onItemClick: (storeId: number) => void;
}

const SidebarStoreItemComponent: React.FC<SidebarStoreItemProps> = ({item, onItemClick}) => {

    const handleClick = () => {
        onItemClick(item.id);
    };

    return (
        <Card className="sidebar-store-item" onClick={handleClick}>
            <Card.Img
                className="sidebar-store-item-img"
                src={`${BACKEND_URL}/${item?.image_path}`} onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = 'https://placehold.co/600x400?text=No+Image';
            }}/>
            <Card.Body>
                <Card.Title>{item.name}</Card.Title>
                <Card.Text>
                    <RatingBadgeComponent rating={item.rating_average} text={''} bgColor='secondary'
                                          badgeSize='small'></RatingBadgeComponent>
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default SidebarStoreItemComponent;
