import React, { useState, useEffect } from 'react';
import SidebarStoreGridComponent from './SidebarStoresGridComponent';
import { Store } from '../../../models/store';
import { fetchStoresByZip } from '../../../api/apiService';

interface SidebarProps {
    zipCode: string;
    storeId : number;
}

const SidebarComponent: React.FC<SidebarProps> = ({ zipCode, storeId }) => {
    const [stores, setStores] = useState<Store[] | null>(null);
  
    useEffect(() => {
      const fetchStoresData = async () => {
        try {
          const mappedStores: Store[] = await fetchStoresByZip(zipCode);
          const filteredStores = mappedStores.filter( store => store.id !== storeId);
          setStores(filteredStores);
        } catch (error) {
          console.log("error fetching data in SidebarComponent");
        }
      };
  
      fetchStoresData();
    }, [zipCode, storeId]);

    return (
        <div>
                <h2 className='mb-3'>{zipCode}</h2>
            <SidebarStoreGridComponent storeItems={stores ?? []}></SidebarStoreGridComponent>
        </div>

    );
};

export default SidebarComponent;