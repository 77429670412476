import React, {useEffect, useState, useMemo} from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
} from 'material-react-table';
import {Link, useNavigate} from 'react-router-dom';
import {acceptStoreById, deleteStoreById, fetchStoresInProgress} from '../../api/apiService';
import {useLogin} from '../../contexts/LoginContext';
import {Store} from '../../models/store';
import {Box, Typography} from '@mui/material';
import {BACKEND_URL} from '../../api/apiConstants';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons CSS

const StoreManagement: React.FC = () => {
    const {user} = useLogin();
    const [stores, setStores] = useState<Store[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (user?.role !== "ADMIN") {
            navigate('/');
        }
        fetchStoresList();
    }, [user]);

    const fetchStoresList = async () => {
        try {
            const storeList: Store[] = await fetchStoresInProgress();
            setStores(storeList);
        } catch (error) {
            console.error('Error fetching stores', error);
        }
    };

    const deleteStore = async (storeId: number) => {
        try {
            await deleteStoreById(storeId);
            setStores(stores.filter(store => store.id !== storeId));
        } catch (error) {
            console.error('Error deleting store', error);
        }
    };

    const acceptStore = async (storeId: number) => {
        try {
            await acceptStoreById(storeId);
            setStores(stores.filter(store => store.id !== storeId));
        } catch (error) {
            console.error('Error accepting store', error);
        }
    };

    const columns = useMemo<MRT_ColumnDef<Store>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Store Name',
                size: 200,
                Cell: ({cell}) => {
                    const storeId = cell.row.original.id;
                    const store = stores.find(store => store.id === storeId);
                    const storeName = cell.getValue<string>();
                    return (
                        <Link
                            to={`/store/${storeId}`}
                            style={{
                                textDecoration: 'none',
                                color: 'inherit',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: 'bold',
                            }}
                        >
                            <img
                                src={`${BACKEND_URL}/${store?.image_path}`}
                                style={{width: '30px', height: '30px', marginRight: '10px', borderRadius: '50%'}}
                            />
                            {storeName}
                        </Link>
                    );
                },
            },
            {
                accessorKey: 'status',
                header: 'Status',
                size: 150,
                Cell: ({cell}) => {
                    const status = cell.getValue<string>();
                    return (
                        <span style={{color: status === 'REPORTED' ? 'red' : 'inherit'}}>
              {status}
            </span>
                    );
                },
            },
            {
                accessorKey: 'actions',
                header: 'Actions',
                size: 100,
                Cell: ({cell}) => {
                    const storeId = cell.row.original.id;
                    return (
                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            <i onClick={() => acceptStore(storeId)} className="bi bi-check-lg"
                               style={{cursor: 'pointer', color: 'green'}}></i>
                            <i onClick={() => deleteStore(storeId)} className="bi bi-x-lg"
                               style={{cursor: 'pointer', color: 'red'}}></i>
                        </div>
                    );
                },
            },
        ],
        [stores]
    );

    return (
        <div className='page-holder'>
            <div className='body-wrapper'>
                <Box sx={{m: '2rem 0', mt: '5rem'}}>
                    <Typography variant="h4" sx={{mb: '1rem'}}>Alle Läden</Typography>
                    <MaterialReactTable
                        columns={columns}
                        data={stores}
                        enableSorting={true}
                        enableRowSelection={false}
                        initialState={{
                            pagination: {pageSize: 5, pageIndex: 0},
                            sorting: [{id: 'name', desc: false}],
                            showGlobalFilter: true,
                        }}
                        globalFilterFn='contains'
                        enableColumnActions={false} // Disable the three dots menu
                        enableColumnFilters={false} // Disable column filters
                        enableDensityToggle={false} // Disable density toggle
                        muiTableBodyRowProps={({row}) => ({
                            style: {
                                backgroundColor: (row.original as Store).status === 'REPORTED' ? 'rgba(255, 0, 0, 0.1)' : 'inherit',
                            },
                        })}
                    />
                </Box>
            </div>
        </div>
    );
};

export default StoreManagement;
