import { User } from "./user";
import { Store } from "./store";

export class Comment {
    id: number;
    user: User;
    store: Store;
    upvotes: number;
    reply_to: number | null;
    headline: string;
    content: string;
    created_at: Date;

    constructor(id: number, user: User, store: Store, upvotes: number, reply_to: number | null, headline: string, content: string, created_at: Date) {
        this.id = id;
        this.user = user;
        this.store = store;
        this.upvotes = upvotes;
        this.reply_to = reply_to;
        this.headline = headline;
        this.content = content;
        this.created_at = created_at;
    }
}