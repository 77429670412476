import React from 'react';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './StoreItemComponent.scss';
import RatingBadgeComponent from '../../StoreComponent/CommentSectionComponent/CommentRatingComponent';
import {Store} from '../../../models/store';
import {CONSTANTS} from '../../../constants';
import {BACKEND_URL} from '../../../api/apiConstants';

interface StoreItemProps {
  item: Store;
  onItemClick: (storeId: number) => void;
  alternativeRating?: number
}

const StoreItem: React.FC<StoreItemProps> = ({ item, onItemClick, alternativeRating }) => {

  const handleClick = () => {
    onItemClick(item.id);
  };

  const getTooltipValue = (rating: number) => {
    return rating !== 0 ? rating : "-";
  }

  const renderTooltip = (props: any) => (
      <Tooltip id="rating-tooltip" {...props}>
        {CONSTANTS.BREAD}: {getTooltipValue(item.bread_average)}/10<br/>
        {CONSTANTS.MEAT}: {getTooltipValue(item.meat_average)}/10<br/>
        {CONSTANTS.VEGETABLES}: {getTooltipValue(item.vegetable_average)}/10<br/>
        {CONSTANTS.SAUCE}: {getTooltipValue(item.sauce_average)}/10<br/>
        {CONSTANTS.TOTAL}: {getTooltipValue(item.rating_average)}/10<br/>
        {CONSTANTS.PRICE_PERFORMANCE_SHORT}: {getTooltipValue(item.price_performance_average)}/10<br/>
        Bewertungen: {item.rating_amount}
      </Tooltip>
  );

  return (
      <Card className={"store-item"} style={{ width: '100%', cursor: 'pointer' }} onClick={handleClick}>
        <Card.Img style={{ height: alternativeRating ? '100px' : '180px' }} className="store-item-image" variant="top" src={`${BACKEND_URL}/${item.image_path}`} onError={(e) => {
          const target = e.target as HTMLImageElement;
          target.src = 'https://placehold.co/600x400?text=No+Image';
        }} />
        <Card.Body>
          <Card.Title className='store-name'>{item.name}</Card.Title>
          <Card.Text>
            <div className='d-flex justify-content-center'>{item.zip} {item.city}</div>
            <div className='d-flex justify-content-center'>{item.street} {item.house_nr}</div>
            <div className="total-top-rating-container mt-2 d-flex justify-content-center">
              {alternativeRating ? (
                  <div className="text-center">
                    <RatingBadgeComponent rating={alternativeRating} bgColor="secondary" text={''} badgeSize='small'></RatingBadgeComponent>
                  </div>
              ) : (
                  <OverlayTrigger placement="bottom" overlay={renderTooltip}>
                    <div className="text-center">
                      <RatingBadgeComponent rating={item.rating_average} bgColor="secondary" text={''} badgeSize='small'></RatingBadgeComponent>
                    </div>
                  </OverlayTrigger>
              )}
              {!alternativeRating && <div>({item.rating_amount})</div>}
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
  );
};

export default StoreItem;
