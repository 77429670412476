import React, {useState, ChangeEvent, FormEvent, useEffect} from 'react';
import {Card, Button, Row, Col, Form} from 'react-bootstrap';
import RatingBadgeComponent from './CommentRatingComponent';
import {CONSTANTS} from '../../../constants';
import {Comment} from '../../../models/comment';
import {Rating} from '../../../models/rating';
import {
    submitRatingComment,
    fetchRepliesToCommentId,
    submitCommentUpvote,
    checkCommentUpvote
} from '../../../api/apiService';
import {useLogin} from '../../../contexts/LoginContext';
import {BACKEND_URL} from '../../../api/apiConstants';


interface CommentCardProps {
    comment: Comment | null;
    rating: Rating;
    storeId: string | null;
}

const CommentCardComponent: React.FC<CommentCardProps> = ({comment, rating, storeId}) => {
    const [showReplyInput, setShowReplyInput] = useState(false);
    const [replyText, setReplyText] = useState('');
    const [replies, setReplies] = useState<Comment[]>([]);
    const [showReplies, setShowReplies] = useState(false);
    const [commentUpvotedStatus, setCommentUpvotedStatus] = useState<boolean>(false);
    /* Should be handled differently somewhen in the future. But as the comment object is coming from outside this component
    * I for now decided to handle an upvote count change in the frontend aswell.
    */
    const [commentUpvoteCount, setCommentUpvoteCount] = useState<number>(comment?.upvotes || 0);
    const [isOwnComment, setIsOwnComment] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState(false);

    const {user} = useLogin();

    const handleReplyClick = () => {
        setShowReplyInput(!showReplyInput);
    };

    const handleCommentUpvote = async () => {
        if (user) {
            if (isOwnComment) {
                alert("Opfer!");
                return;
            }

            const upvoteData = {
                user_id: user?.id,
                comment_id: comment?.id,
            };

            try {
                const data: any = await submitCommentUpvote(upvoteData);
                if (data && data.hasOwnProperty('upvoted')) {
                    setCommentUpvotedStatus(data.upvoted);
                    /* Temporarily add frontend handling of comment upvote count (Is also handled in the backend. 
                    *But as the comment object isn't fetched again when submitting an upvote I for now handled it as follows
                    */
                    if (data.upvoted) {
                        setCommentUpvoteCount(commentUpvoteCount + 1);
                    } else {
                        setCommentUpvoteCount(commentUpvoteCount - 1);
                    }
                }
            } catch (error) {
                console.error('Error submitting upvote:', error);
            }
        }
    };

    const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setReplyText(event.target.value);
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log('Submitted:', replyText);

        const updatedCommentFormData = {
            user_id: user?.id,
            store_id: storeId,
            upvotes: 0,
            reply_to: comment?.id,
            comment_headline: '',
            comment_content: replyText
        };

        setReplyText('');
        setShowReplyInput(false);
        await submitRatingComment(updatedCommentFormData);
        fetchReplies();
    };

    useEffect(() => {

        const checkCommentUpvoteStatus = async () => {
            try {
                if (user && comment) {

                    const upvoteData = {
                        comment_id: comment.id,
                    };

                    const commentUpvoted: boolean = await checkCommentUpvote(upvoteData);
                    setCommentUpvotedStatus(commentUpvoted);
                }
            } catch (error) {
                console.error('Error fetching replies in CommentCardComponent:', error);
            }
        }

        fetchReplies();
        checkCommentUpvoteStatus();
        setCommentUpvoteCount(comment?.upvotes || 0);

        if (user && comment) {
            setIsOwnComment(user.id === comment.user.id);
        }
    }, [storeId, user, comment]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchReplies = async () => {
        try {
            if (storeId !== null && comment) {
                const allReplies: Comment[] = await fetchRepliesToCommentId(comment.id);
                setReplies(allReplies);
            }
        } catch (error) {
            console.error('Error fetching replies in CommentCardComponent:', error);
        }
    };


    const renderReplies = () => {
        return replies.map((reply, index) => (
            <div key={index} className="mb-2">
                <Row>
                    <Col md={1}>
                    </Col>
                    <Col md={6}>
                        <img className='rating-user-pp-small me-2' src={`${BACKEND_URL}/${reply.user.picture}`}></img>
                        <small>
                            {reply.user.username} am {reply.created_at ? formatDate(new Date(reply.created_at)) : 'Datum nicht verf�gbar'}
                        </small>
                    </Col>
                    <Col md={5}></Col>
                </Row>
                <Row>
                    <Col md={1}>
                    </Col>
                    <Col md={10}>
                        <p className="ms-2">{reply.content}</p>
                    </Col>
                </Row>
            </div>
        ));
    };


    const handleShowReplies = () => {
        setShowReplies(!showReplies);
    };

    const calculateRatingAverage = (rating: Rating) => {
        // Sammle alle Bewertungen in einem Array
        const values = [rating.meat, rating.bread, rating.vegetable, rating.sauce];

        // Filtere die Bewertungen heraus, die 0 sind
        const nonZeroValues = values.filter(value => value !== 0);

        // Wenn alle Werte 0 sind, gib 0 als Durchschnitt zurück
        if (nonZeroValues.length === 0) {
            return 0;
        }

        // Berechne den Durchschnitt der verbleibenden Werte
        const average = nonZeroValues.reduce((acc, value) => acc + value, 0) / nonZeroValues.length;

        // Runde den Durchschnitt auf eine Dezimalstelle
        return Math.round(average * 10) / 10;
    };


    const formatDate = (date: Date): string => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert
        const year = String(date.getFullYear()).slice(-2);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };


    return (
        <Card className="mb-3 shadow-box">
            <Card.Body>
                <Row className="mb-4">
                    <Col md={3}>
                        <Col md={12} className='text-center mb-1'>
                            {comment?.created_at ? formatDate(new Date(comment.created_at)) : 'Datum nicht verfügbar'}
                        </Col>
                    </Col>
                    <Col md={7} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <RatingBadgeComponent
                          rating={rating.bread}
                          bgColor="bread"
                          text={isMobile ? CONSTANTS.BREAD_MOBILE : CONSTANTS.BREAD}
                          badgeSize='medium'
                        />
                        <RatingBadgeComponent
                          rating={rating.meat}
                          bgColor="meat"
                          text={isMobile ? CONSTANTS.MEAT_MOBILE : CONSTANTS.MEAT}
                          badgeSize='medium'
                        />
                        <RatingBadgeComponent
                          rating={rating.vegetable}
                          bgColor="vegetable"
                          text={isMobile ? CONSTANTS.VEGETABLES_MOBILE : CONSTANTS.VEGETABLES}
                          badgeSize='medium'
                        />
                        <RatingBadgeComponent
                          rating={rating.sauce}
                          bgColor="sauce"
                          text={isMobile ? CONSTANTS.SAUCE_MOBILE : CONSTANTS.SAUCE}
                          badgeSize='medium'
                        />
                        <RatingBadgeComponent
                          rating={calculateRatingAverage(rating)}
                          bgColor="secondary"
                          text={isMobile ? CONSTANTS.TOTAL_MOBILE : CONSTANTS.TOTAL}
                          badgeSize='medium'
                        />
                        <RatingBadgeComponent
                          rating={rating.price_performance}
                          bgColor="price-performance"
                          text={isMobile ? CONSTANTS.PRICE_PERFORMANCE_MOBILE : CONSTANTS.PRICE_PERFORMANCE}
                          badgeSize='medium'
                        />
                    </Col>
                    <Col md={2}>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} className="mb-3">
                        <Col md={12}>
                            <div className='text-center'>
                                <a href={`https://www.kebapp.me/user/${comment?.user?.id}`} className="no-link-style">
                                    <img className='rating-user-pp' src={`${BACKEND_URL}/${comment?.user?.picture}`} alt="User profile picture" />
                                </a>
                            </div>
                        </Col>
                        <div className='text-center'>
                            <a href={`https://www.kebapp.me/user/${comment?.user?.id}`} className="no-link-style">
                                <div className='small-subtitle mt-1 user-name'>{comment?.user.username}</div>
                            </a>
                        </div>
                    </Col>
                    <Col md={9} className="text-right">
                        <div className="small-subtitle comment-container">{comment?.headline}</div>
                        <div className="mb-2" dangerouslySetInnerHTML={{__html: (comment?.content || '').replace(/\n/g, '<br />')}}></div>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className="text-muted text-right card-footer-white">
                <div>
                    <Row>
                        <Col xs={10}>
                            <Button variant="outline-secondary" size="sm" onClick={handleReplyClick}>
                                <i className="bi bi-chat-square-quote me-1"></i>
                                Antworten
                            </Button>
                        </Col>
                        <Col xs={2} className="d-flex align-items-center justify-content-end">
                            <Button
                                variant={"outline-secondary"}
                                onClick={handleCommentUpvote}
                                disabled={isOwnComment}
                                className={isOwnComment ? 'disabled-upvote-button' : ''}
                            >
                                {commentUpvotedStatus ? (
                                    <i className="bi bi-hand-thumbs-up-fill"></i>
                                ) : (
                                    <i className="bi bi-hand-thumbs-up"></i>
                                )}
                            </Button>
                            <p className="upvote-count">{commentUpvoteCount}</p>
                        </Col>
                    </Row>
                </div>
                {showReplyInput && (
                    <Form onSubmit={handleSubmit}>
                        <Form.Control
                            className="mt-2"
                            as="textarea"
                            rows={3}
                            placeholder="Antwort schreiben..."
                            value={replyText}
                            onChange={handleInputChange}
                        />
                        <div className="d-flex justify-content-end">
                            <Button variant="secondary" type="submit" className="mt-2">
                                Submit
                            </Button>
                        </div>
                    </Form>
                )}
                {replies.length !== 0 && (
                    <div className='mb-3'>
                        <span onClick={handleShowReplies} style={{cursor: 'pointer'}}>
                            {replies.length} Antwort(en) <i className="bi bi-caret-down-fill"></i>
                        </span>
                    </div>
                )}
                {showReplies && renderReplies()}
            </Card.Footer>

        </Card>
    );
};

export default CommentCardComponent;
