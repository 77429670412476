// apiService.ts
import axios, { AxiosResponse, InternalAxiosRequestConfig, AxiosHeaders } from 'axios';
import {Store} from '../models/store';
import {Rating} from '../models/rating';
import {Comment} from '../models/comment';
import {User} from '../models/user';
import {BACKEND_URL} from "./apiConstants";
import {createContext} from "react";
import {useLogin} from "../contexts/LoginContext";

const API_URL = BACKEND_URL + "/api/";

const apiInstance = axios.create({
  baseURL: API_URL,
  // Hier können Sie weitere Konfigurationen vornehmen, z.B. Headers
});

const apiInstanceUnauthorized = axios.create({
  baseURL: API_URL,
});

interface FailedRequest {
  resolve: (config: InternalAxiosRequestConfig<any>) => void;
  reject: (error: any) => void;
  config: InternalAxiosRequestConfig<any>;
}

let isRefreshing = false;
let failedQueue: FailedRequest[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else if (token) {
      prom.resolve({
        ...prom.config,
        headers: new AxiosHeaders({
          ...prom.config.headers?.toJSON(),
          Authorization: `Bearer ${token}`
        })
      });
    }
  });
  failedQueue = [];
};

apiInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig<any>> => {
      let accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refresh");

      if (!accessToken) {
        return config;
      }

      const decodedToken = decodeJWT(accessToken);
      const tokenExpiration = decodedToken.exp || 0;
      const now = Date.now() / 1000;

      if (tokenExpiration > now) {
        config.headers = config.headers || new AxiosHeaders();
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
      }

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const response: AxiosResponse<{ access: string }> = await apiInstanceUnauthorized.post(`/user/refresh-token/`, { refresh: refreshToken });
          accessToken = response.data.access;
          localStorage.setItem("accessToken", accessToken);
          processQueue(null, accessToken);
        } catch (error) {
          processQueue(error, null);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refresh");
          const { logout } = useLogin();
          logout();
          window.location.href = '/';
          return Promise.reject(error);
        } finally {
          isRefreshing = false;
        }
      }

      return new Promise<InternalAxiosRequestConfig<any>>((resolve, reject) => {
        failedQueue.push({
          resolve,
          reject,
          config
        });
      }).then((newConfig) => {
        newConfig.headers = newConfig.headers || new AxiosHeaders();
        newConfig.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
        return newConfig;
      });
    },
    (error) => {
      return Promise.reject(error);
    }
);

apiInstance.interceptors.response.use(
    response => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const response = await apiInstanceUnauthorized.post('/user/refresh-token/', {
            refresh: localStorage.getItem("refresh")
          });
          const { access } = response.data;
          localStorage.setItem("accessToken", access);
          originalRequest.headers.Authorization = `Bearer ${access}`;
          return apiInstance(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      }
      return Promise.reject(error);
    }
);

function decodeJWT(token: string): any {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const fetchStores = async (): Promise<Store[]> => {
  try {
    const response = await apiInstanceUnauthorized.get('/store/get-stores/');
    const apiData = response.data.stores;

    const stores: Store[] = apiData.map((storeData: any) => {

      roundRatingValues(storeData);

      return new Store(
        storeData.id,
        storeData.name,
        storeData.rating_amount,
        storeData.rating_average,
        storeData.vegetable_average,
        storeData.meat_average,
        storeData.bread_average,
        storeData.sauce_average,
        storeData.price_performance_average,
        new Date(storeData.created_at),
        storeData.street,
        storeData.house_nr,
        storeData.zip,
        storeData.city,
        storeData.image_path,
        storeData.user,
        storeData.status
      );
    });

    return stores;
  } catch (error) {
    console.error('Error fetching stores:', error);
    throw error;
  }
};

export const fetchStoresInProgress = async (): Promise<Store[]> => {
  try {
    const response = await apiInstance.get('/store/get-stores-in-progress/');
    const apiData = response.data.stores;

    const stores: Store[] = apiData.map((storeData: any) => {

      roundRatingValues(storeData);

      return new Store(
        storeData.id,
        storeData.name,
        storeData.rating_amount,
        storeData.rating_average,
        storeData.vegetable_average,
        storeData.meat_average,
        storeData.bread_average,
        storeData.sauce_average,
        storeData.price_performance_average,
        new Date(storeData.created_at),
        storeData.street,
        storeData.house_nr,
        storeData.zip,
        storeData.city,
        storeData.image_path,
        storeData.user,
        storeData.status
      );
    });

    return stores;
  } catch (error) {
    console.error('Error fetching stores:', error);
    throw error;
  }
};

export const fetchTopTenStores = async (): Promise<Store[]> => {
  try {
    const response = await apiInstanceUnauthorized.get('/store/get-top-ten-stores/');
    const apiData = response.data.stores;

    const stores: Store[] = apiData.map((storeData: any) => {

      roundRatingValues(storeData);

      return new Store(
        storeData.id,
        storeData.name,
        storeData.rating_amount,
        storeData.rating_average,
        storeData.vegetable_average,
        storeData.meat_average,
        storeData.bread_average,
        storeData.sauce_average,
        storeData.price_performance_average,
        new Date(storeData.created_at),
        storeData.street,
        storeData.house_nr,
        storeData.zip,
        storeData.city,
        storeData.image_path,
        storeData.user,
        storeData.status
      );
    });

    return stores;
  } catch (error) {
    console.error('Error fetching stores:', error);
    throw error;
  }
};

export const fetchStoresByZip = async (zip : string): Promise<Store[]> => {
  try {
    const response = await apiInstanceUnauthorized.get(`/store/get-stores-by-zip/${zip}`);
    const apiData = response.data.stores;

    const stores: Store[] = apiData.map((storeData: any) => {
      roundRatingValues(storeData);
      return new Store(
        storeData.id,
        storeData.name,
        storeData.rating_amount,
        storeData.rating_average,
        storeData.vegetable_average,
        storeData.meat_average,
        storeData.bread_average,
        storeData.sauce_average,
        storeData.price_performance_average,
        new Date(storeData.created_at),
        storeData.street,
        storeData.house_nr,
        storeData.zip,
        storeData.city,
        storeData.image_path,
        storeData.user,
        storeData.status
      );
    });

    return stores;
  } catch (error) {
    console.error('Error fetching stores by zip:', error);
    throw error;
  }
};

export const fetchStoreById = async (id : number): Promise<Store> => {
  try {
    const response = await apiInstanceUnauthorized.get(`/store/get-store/${id}`);
    const storeData = response.data.store;

    roundRatingValues(storeData);

    const store: Store = new Store(
      storeData.id,
      storeData.name,
      storeData.rating_amount,
      storeData.rating_average,
      storeData.vegetable_average,
      storeData.meat_average,
      storeData.bread_average,
      storeData.sauce_average,
      storeData.price_performance_average,
      new Date(storeData.created_at),
      storeData.street,
      storeData.house_nr,
      storeData.zip,
      storeData.city,
      storeData.image_path,
      storeData.user,
      storeData.status
    );

    return store;
  } catch (error) {
    console.error('Error fetching store by id:', error);
    throw error;
  }
};

export const deleteStoreById = async (id : number): Promise<void> => {
  try {
    await apiInstance.delete(`/store/delete-store/${id}`);
  } catch (error) {
    console.error('Error fetching store by id:', error);
    throw error;
  }
};

export const acceptStoreById = async (id : number): Promise<void> => {
  try {
    await apiInstance.put(`/store/accept-store/${id}`);
  } catch (error) {
    console.error('Error updating store by id:', error);
    throw error;
  }
};

export const reportStoreById = async (id : number): Promise<void> => {
  try {
    await apiInstanceUnauthorized.put(`/store/report-store/${id}`);
  } catch (error) {
    console.error('Error reporting store by id:', error);
    throw error;
  }
};

export const createNewStore = async (newStore: Store, imageFile: File | null): Promise<void> => {
  try {
    const formData = new FormData();

    Object.entries(newStore).forEach(([key, value]) => {
      formData.append(key, String(value));
    });

    if (imageFile) {
      formData.append('image', imageFile);
    }

    await apiInstance.post('/store/add-new-store/', formData);
  } catch (error) {
    throw error;
  }
};

export const submitRatingComment = async (comment: any) => {
  try {
    const response = await apiInstance.post('/comment/submit-rating-comment/', comment);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitRating = async (rating: any) => {
  try {
    const response = await apiInstance.post('/rating/submit-rating/', rating);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const submitCommentUpvote = async (upvote: any) => {
  try {
    const response = await apiInstance.post('/upvote/upvote/', upvote);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkCommentUpvote = async (upvoteData: any) => {
  try {
    const response = await apiInstance.post('/upvote/check_upvote_status/', upvoteData);
    const data = response.data;

    // Überprüfen, ob ein Upvote vorhanden ist und entsprechend true oder false zurückgeben
    if (data.status === 'success' && data.upvote_exists !== undefined) {
      return data.upvote_exists;
    } else {
      console.error('Error checking upvote:', data.message);
      return false; // Wenn ein Fehler auftritt oder die Antwort unerwartet ist, gebe false zurück
    }
  } catch (error) {
    console.error('Error checking upvote:', error);
    return false; // Wenn ein Fehler auftritt, gebe false zurück
  }
};


export const fetchRatingsByStoreId = async (storeId : number): Promise<Rating[]> => {
  try {
    const response = await apiInstanceUnauthorized.get(`/rating/get-ratings-by-store-id/${storeId}`);
    const apiData = response.data.ratings;

    const ratings: Rating[] = apiData.map((ratingData: any) => {
      return new Rating(
          ratingData.id,
          ratingData.store_id,
          ratingData.username,
          ratingData.vegetable,
          ratingData.meat,
          ratingData.bread,
          ratingData.sauce,
          ratingData.price_performance,
          ratingData.comment_id,
          new Date(ratingData.created_at)
      );
  });
  

    return ratings;
  } catch (error) {
    console.error('Error fetching stores by zip:', error);
    throw error;
  }
};

export const fetchRatingsByUserId = async (userId : number): Promise<Rating[]> => {
  try {
    const response = await apiInstance.get(`/rating/get-ratings-by-user-id/${userId}`);
    const apiData = response.data.ratings;

    const ratings: Rating[] = apiData.map((ratingData: any) => {
      return new Rating(
          ratingData.id,
          ratingData.store_id,
          ratingData.username,
          ratingData.vegetable,
          ratingData.meat,
          ratingData.bread,
          ratingData.sauce,
          ratingData.price_performance,
          ratingData.comment_id,
          new Date(ratingData.created_at)
      );
    });


    return ratings;
  } catch (error) {
    console.error('Error fetching stores by zip:', error);
    throw error;
  }
};

export const fetchCommentsByIds = async (commentIds: number[]): Promise<Comment[]> => {
  try {
    const response = await apiInstanceUnauthorized.post('/comment/get-comments-by-ids/', { comment_ids: commentIds });
    const apiData = response.data.comments;

    const comments: Comment[] = apiData.map((commentData: any) => {
      return {
        id: commentData.id,
        user: commentData.user,
        store: commentData.store,
        upvotes: commentData.upvotes,
        reply_to: commentData.reply_to,
        headline: commentData.headline,
        content: commentData.content,
        created_at: commentData.created_at,
      };
    });

    return comments;
  } catch (error) {
    console.error('Error fetching comments by ids:', error);
    throw error;
  }
};


export const sendGoogleLoginCredentials = async (credentials: any): Promise<User> => {
  try {
    const response = await apiInstanceUnauthorized.post('/user/send-google-login-credentials/', credentials);
    const userData = response.data.user;

    const user : User = new User(
      userData.id,
      userData.email,
      userData.given_name,
      userData.family_name,
      userData.name,
      userData.picture,
      userData.username,
      userData.role,
      new Date(userData.created_at)
    );

    localStorage.setItem("accessToken", userData.access);
    localStorage.setItem("refresh", userData.refresh);

    return user;
  } catch (error) {
    console.error('Error send google Credentials:', error);
    throw error;
  }
};

export const changeUsername = async (newUsername: string) => {
  try {
    const response = await apiInstance.post('/user/change-username/', {
      new_username: newUsername,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getExistingRatingAndComment = async (user_id: number, store_id: number) => {
  try {
    const response = await apiInstanceUnauthorized.get('/rating/get-existing-rating-and-comment/', {
      params: {
        user_id: user_id,
        store_id: store_id,
      },
    });

    const ratingData = response.data;

    const rating: Rating = new Rating(
      ratingData.id,
      ratingData.store_id,
      ratingData.username,
      ratingData.vegetable,
      ratingData.meat,
      ratingData.bread,
      ratingData.sauce,
      ratingData.price_performance,
      ratingData.comment_id,
      new Date(ratingData.created_at)
    );

    const comment: Comment = new Comment(
      ratingData.comment.id,
      ratingData.comment.user,
      ratingData.comment.store,
      ratingData.comment.upvotes,
      ratingData.comment.reply_to,
      ratingData.comment.headline,
      ratingData.comment.content,
      ratingData.comment.created_at,
    );

    return { rating, comment };
  } catch (error) {
    throw error;
  }
}

export const fetchRepliesToCommentId = async (commentId: number): Promise<Comment[]> => {
  try {
    const response = await apiInstance.post('/comment/get-replies-to-comment/', { commentId });
    const apiData = response.data.comments;

    const comments: Comment[] = apiData.map((commentData: any) => {
      return {
        id: commentData.id,
        user: commentData.user,
        store: commentData.store,
        upvotes: commentData.upvotes,
        reply_to: commentData.reply_to,
        headline: commentData.headline,
        content: commentData.content,
        created_at: commentData.created_at,
      };
    });

    return comments;
  } catch (error) {
    console.error('Error fetching comments by ids:', error);
    throw error;
  }
};

export const changeProfilePicture = async (user_id: number, imageFile: File): Promise<User> => {
  try {
    const formData = new FormData();

    if (imageFile) {
      formData.append('image', imageFile);
    }

    const response = await apiInstance.post('/user/change-profile-picture/', formData);
    const userData = response.data.user;

    const user : User = new User(
        userData.id,
        userData.email,
        userData.given_name,
        userData.family_name,
        userData.name,
        userData.picture,
        userData.username,
        userData.role,
        new Date(userData.created_at)
    );

    return user;
  } catch (error) {
    throw error;
  }
};
export const searchStores = async (searchQuery: string): Promise<Store[]> => {
  try {
    const response = await apiInstanceUnauthorized.get(`/store/search-stores/?q=${searchQuery}`);
    const apiData = response.data;

    return apiData.map((storeData: any) => {
      return new Store(
          storeData.id,
          storeData.name,
          storeData.rating_amount,
          storeData.rating_average,
          storeData.vegetable_average,
          storeData.meat_average,
          storeData.bread_average,
          storeData.sauce_average,
          storeData.price_performance_average,
          new Date(storeData.created_at),
          storeData.street,
          storeData.house_nr,
          storeData.zip,
          storeData.city,
          storeData.image_path,
          storeData.user,
          storeData.status
      );
    });
  } catch (error) {
    console.error('Search error:', error);
    throw error;
  }
};

export const searchUsers = async (searchQuery: string): Promise<Store[]> => {
  try {
    const response = await apiInstanceUnauthorized.get(`/user/search-users/?q=${searchQuery}`);
    const apiData = response.data;

    return apiData.map((userData: any) => {
      return new User(
          userData.id,
          userData.email,
          userData.given_name,
          userData.family_name,
          userData.name,
          userData.picture,
          userData.username,
          userData.role,
          new Date(userData.created_at)
      );
    });
  } catch (error) {
    console.error('Search error:', error);
    throw error;
  }
};

export const getUserById = async (id: number): Promise<User> => {
  try {
    const response = await apiInstance.get(`/user/get-user/${id}`);
    const userData = response.data.user;

    return new User(
        userData.id,
        userData.email,
        userData.given_name,
        userData.family_name,
        userData.name,
        userData.picture,
        userData.username,
        userData.role,
        new Date(userData.created_at)
    );
  } catch (error) {
    console.error('Error fetching user by id:', error);
    throw error;
  }
};

export const fetchTotalUpvotesByUserId = async (userId: number): Promise<number> => {
  try {
    const response = await apiInstanceUnauthorized.post('/comment/total-upvotes-by-user/', { user_id: userId });
    const data = response.data;
    return data.total_upvotes;
  } catch (error) {
    console.error('Error fetching total upvotes by user id:', error);
    throw error;
  }
};

export const fetchTotalUpvotesGivenByUserId = async (userId: number): Promise<number> => {
  try {
    const response = await apiInstanceUnauthorized.post('/upvote/total-upvotes-given-by-user/', { user_id: userId });
    const data = response.data;
    return data.total_upvotes;
  } catch (error) {
    console.error('Error fetching total upvotes by user id:', error);
    throw error;
  }
};

export const deleteOwnProfile = async (): Promise<void> => {
  try {
    const response = await apiInstance.delete('/user/delete-user');
    if (response.status === 204) {
      console.log('Profile successfully deleted');
    } else {
      console.warn('Unexpected response status:', response.status);
    }
  } catch (error) {
    console.error('Error deleting own profile:', error);
    throw error;
  }
};



function roundRatingValues(storeData: any) {
  storeData.rating_average = Math.round(storeData.rating_average * 10) / 10;
  storeData.vegetable_average = Math.round(storeData.vegetable_average * 10) / 10;
  storeData.meat_average = Math.round(storeData.meat_average * 10) / 10;
  storeData.bread_average = Math.round(storeData.bread_average * 10) / 10;
  storeData.sauce_average = Math.round(storeData.sauce_average * 10) / 10;
  storeData.price_performance_average = Math.round(storeData.price_performance_average * 10) / 10;
}
