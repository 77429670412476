import React from 'react';
import './FooterComponent.scss';
import ImpressumComponent from '../ImpressumComponent/ImpressumComponent';
import DonateButton from "./DonateButton";
import ManualComponent from "../ManualComponent/ManualComponent";

const FooterComponent = () => {

    return (
        <div className='footer-container'>
            <div className='content-container'>
                <div className='impressum-item'><DonateButton /></div>
                <div className='impressum-item'><ImpressumComponent /></div>
                {/*<div className='impressum-item impressum-item-margin'><ManualComponent /></div>*/}
            </div>
        </div>
    );
};

export default FooterComponent;
