export class User {
    id: number;
    email: string;
    given_name: string;
    family_name: string;
    name: string;
    picture: string;
    username: string;
    role: string;
    created_at: Date

    constructor(
        id: number,
        email: string,
        given_name: string,
        family_name: string,
        name: string,
        picture: string,
        username: string,
        role : string,
        created_at: Date
    ) {
        this.id = id;
        this.email = email;
        this.given_name = given_name;
        this.family_name = family_name;
        this.name = name;
        this.picture = picture;
        this.username = username;
        this.role = role;
        this.created_at = created_at;
    }
}

