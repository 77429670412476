import {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import './AddStoreComponent.scss';
import {Col, FloatingLabel, Row, Spinner} from 'react-bootstrap';
import {Store} from '../../../models/store';
import {createNewStore} from '../../../api/apiService';
import {useLogin} from '../../../contexts/LoginContext';

interface AddStoreComponentProps {
    onAddStore: () => void;
}

const initialFormData: Store = {
    id: 0,
    name: '',
    rating_amount: 0,
    rating_average: 0,
    vegetable_average: 0,
    meat_average: 0,
    bread_average: 0,
    sauce_average: 0,
    price_performance_average: 0.0,
    created_at: new Date(),
    street: '',
    house_nr: '',
    zip: '',
    city: '',
    image_path: '',
    user_id: 1,
    status: 'IN_PROGRESS'
};

const AddStoreComponent: React.FC<AddStoreComponentProps> = ({onAddStore}) => {
    const {user} = useLogin();
    const [show, setShow] = useState(false);
    const [storeCreated, setStoreCreated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState<any>({});
    const [isErrorOnRequest, setIsErrorOnRequest] = useState(false);

    const handleClose = () => {
        if (!isLoading) {
            setShow(false);
            setImageFile(null);
            setFormData(initialFormData);
            setErrors({});
            setStoreCreated(false);
        }
    }

    const handleShow = () => setShow(true);

    const validateForm = () => {
        const newErrors: any = {};
        if (!formData.name || formData.name.length < 4 || formData.name.length > 50) {
            newErrors.name = 'Pflichtfeld! Max. 50 Zeichen!';
        }
        if (!formData.zip || !/^\d{4,6}$/.test(formData.zip)) {
            newErrors.zip = 'PLZ muss aus 4 bis 6 Ziffern bestehen.';
        }
        if (!formData.street || formData.street.length > 50) {
            newErrors.street = 'Pflichtfeld! Max. 50 Zeichen!';
        }
        if (!formData.city || formData.city.length > 50) {
            newErrors.city = 'Pflichtfeld! Max. 50 Zeichen!';
        }
        if (!formData.house_nr || formData.house_nr.length < 1 || formData.house_nr.length > 12) {
            newErrors.house_nr = 'Pflichtfeld! Maximal 12 Zeichen!';
        }
        return newErrors;
    };

    const handleSaveChanges = async () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        if (!user) {
            return;
        }

        const newStore = new Store(
            formData.id,
            formData.name,
            formData.rating_amount,
            formData.rating_average,
            formData.vegetable_average,
            formData.meat_average,
            formData.bread_average,
            formData.sauce_average,
            formData.price_performance_average,
            formData.created_at,
            formData.street,
            formData.house_nr,
            formData.zip,
            formData.city,
            formData.image_path,
            user.id,
            formData.status
        );

    try {
      createNewStore(newStore, imageFile)
        .then(() => {
        setIsLoading(false);
        setStoreCreated(true);
        }).catch(() => {
            setIsLoading(false);
            setIsErrorOnRequest(true);
      });
      setIsLoading(true);
      onAddStore();
      console.log('new store created');
    } catch (error) {
      console.error('error when creating new store:', error);
    }

        console.log(newStore);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];

        if (file) {
            setImageFile(file);
            setFormData({...formData, image_path: file.name});
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    return (
        <>
            <>
                <Button variant="secondary" className='text-white add-store-button' onClick={handleShow}>
                    <span className="d-none d-md-inline">Laden hinzufügen</span>
                    <span className="d-md-none">Hinzufügen</span>
                </Button>
                <Button
                    variant="secondary"
                    className="text-white mobile-add-store-button d-md-none"
                    onClick={handleShow}
                >
                    <i className="bi bi-plus-circle"></i>
                </Button>
            </>
            {user !== null && (
                <Modal centered show={show} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Neuen Döner Laden hinzufügen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isLoading && (
                          <Row>
                              <Col className="spinner-container" xs={12}>
                                  <Spinner animation="border" />
                              </Col>
                          </Row>
                        )}
                        {storeCreated && !isLoading && (
                            <Row>
                                <Col xs={12}>
                                    Vielen Dank, dass Sie einen neuen Döner-Laden hinzugefügt haben. Ihr Eintrag wird
                                    nun von einem Administrator geprüft.
                                </Col>
                            </Row>
                        )}
                        {isErrorOnRequest && (
                          <Row>
                              <Col xs={12}>
                                  Sie können maximal 3 Dönerläden pro Tag anlegen.
                                  Bitte versuchen Sie es später noch ein mal.
                              </Col>
                          </Row>
                        )}
                        {!storeCreated && !isLoading && (
                            <Form>
                                <Row>
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Control type="file" accept="image/*" onChange={handleFileChange}/>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="name">
                                            <FloatingLabel label="Name">
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    placeholder="Name"
                                                    isInvalid={!!errors.name}
                                                    autoFocus
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="street">
                                            <FloatingLabel label="Straße">
                                                <Form.Control
                                                    type="text"
                                                    name="street"
                                                    value={formData.street}
                                                    onChange={handleChange}
                                                    placeholder="Straße"
                                                    isInvalid={!!errors.street}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.street}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="house_nr">
                                            <FloatingLabel label="Hausnummer">
                                                <Form.Control
                                                    type="text"
                                                    name="house_nr"
                                                    value={formData.house_nr}
                                                    onChange={handleChange}
                                                    placeholder="Hausnummer"
                                                    isInvalid={!!errors.house_nr}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.house_nr}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="zip">
                                            <FloatingLabel label="PLZ">
                                                <Form.Control
                                                    type="text"
                                                    name="zip"
                                                    value={formData.zip}
                                                    onChange={handleChange}
                                                    placeholder="PLZ"
                                                    isInvalid={!!errors.zip}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.zip}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="city">
                                            <FloatingLabel label="Stadt">
                                                <Form.Control
                                                    type="text"
                                                    name="city"
                                                    value={formData.city}
                                                    onChange={handleChange}
                                                    placeholder="Stadt"
                                                    isInvalid={!!errors.city}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.city}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose} disabled={isLoading}>
                            Schließen
                        </Button>
                        {!storeCreated && (
                            <Button variant="secondary" onClick={handleSaveChanges} disabled={isLoading}>
                                Speichern
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
            )}

            {user === null && (
                <Modal centered show={show} onHide={handleClose} size="lg">
                    <Modal.Header>
                        <Modal.Title>Sie müssen sich zuerst einloggen, um einen neuen Laden hinzuzufügen.</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col className="spinner-container" xs={12}>
                                    <Spinner animation="border" />
                                </Col>
                            </Row>
                        </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
}

export default AddStoreComponent;
