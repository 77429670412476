import React from 'react';
import { ProgressBar } from 'react-bootstrap';

interface RatingProgressBarProps {
  title: string;
  color: string; // Vordergrundfarbe als Hexcode
  rating: number;
}

const RatingProgressBar: React.FC<RatingProgressBarProps> = ({ title, color, rating }) => {
  const calculatePercentage = () => {
    return (rating / 10) * 100;
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <div>{title}</div>
      <ProgressBar
        now={calculatePercentage()}
        label={`${rating}/10`}
        className="custom-progress-bar"
        variant={color}
      />
    </div>
  );
};

export default RatingProgressBar;
