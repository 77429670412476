import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Store } from '../../../models/store';
import SidebarStoreItemComponent from './SidebarStoreItemComponent';
import { useNavigate } from 'react-router-dom';

interface SidebarStoreGridProps {
  storeItems: Store[];
}

const SidebarStoreGridComponent: React.FC<SidebarStoreGridProps> = ({ storeItems }) => {
  const itemsPerRow = 3;
  const navigate = useNavigate();
    
    
  const handleStoreItemClick = (storeId: number) => {
    console.log(`Store item clicked with ID: ${storeId}`);
    navigate(`/store/${storeId}`);
  };

  const renderStoreItems = () => {
    const rows = [];
    for (let i = 0; i < storeItems.length; i += itemsPerRow) {
      const rowItems = storeItems.slice(i, i + itemsPerRow);
      const row = (
        <Row key={i} className="mb-3" style={{ justifyContent: 'left' }}>
          {rowItems.map((item) => (
            <Col key={item.id} xs={12 / itemsPerRow}>
              <SidebarStoreItemComponent item={item} onItemClick={handleStoreItemClick} />
            </Col>
          ))}
        </Row>
      );
      rows.push(row);
    }
    return rows;
  };

  return <div>{renderStoreItems()}</div>;
};

export default SidebarStoreGridComponent;
