import './App.scss';
import StoreComponent from './components/StoreComponent/StoreComponent';
import NavbarComponent from './components/NavbarComponent/NavbarComponent';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import StoresOverviewComponent from './components/StoresComponent/StoresOverviewComponent';
import HomeComponent from './components/HomeComponent/HomeComponent';
import ProfileComponent from './components/ProfileComponent/ProfileComponent';
import UserComponent from "./components/UserComponent/UserComponent";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LoginProvider } from './contexts/LoginContext';
import FooterComponent from './components/FooterComponent/FooterComponent';
import {CookieConsent} from 'react-cookie-consent';
import StoreManagementComponent from './components/StoreManagementComponent/StoreManagementComponent';
// import SearchResultsComponent from "./components/SearchComponent/SearchResultsComponent";

function App() {
  return (
    <GoogleOAuthProvider clientId='1046988430014-q1gn77b6skjiu6fo5p6b139dr4adi1ql.apps.googleusercontent.com'>
      <LoginProvider>
        <div className="App">
            <BrowserRouter>
                <main>
                <NavbarComponent/>
                <div className='navbar-divider'/>
                  <Routes>
                    <Route path="stores" element={<StoresOverviewComponent />} />
                    <Route path="store/:id" element={<StoreComponent />} />
                    <Route path="store-management" element={<StoreManagementComponent />} />
                    <Route path="" element={<HomeComponent />} />
                    <Route path="profile" element={<ProfileComponent />} />
                    <Route path="user/:userId" element={<UserComponent />} />
                    {/*<Route path="/search-results" element={<SearchResultsComponent />} />*/}
                  </Routes>
                </main>
                <FooterComponent/>
            </BrowserRouter>
        </div>
      </LoginProvider>
      <CookieConsent
        location="bottom"
        buttonText="Ich stimme zu"
        cookieName="savedCookies"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          backgroundColor: "#94C69C",
          color: "#fff",
          fontSize: "13px",
          borderRadius: "20px", /* macht den Button rund */
          padding: "10px 20px" /* sorgt für ausreichend Platz innerhalb des Buttons */
        }}
        expires={150}
      >
        Diese Website verwendet Cookies, um Ihre Erfahrung zu verbessern. Durch die Nutzung unserer Website stimmen Sie der Verwendung von Cookies zu.
      </CookieConsent>
    </GoogleOAuthProvider>
  );
}

export default App;
