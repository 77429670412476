import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import '../ProfileComponent/ProfileComponent.scss';
import {useLogin} from '../../contexts/LoginContext';
import {useNavigate, useParams} from 'react-router-dom';
import {
    fetchRatingsByUserId,
    fetchStoreById,
    fetchTotalUpvotesByUserId,
    fetchTotalUpvotesGivenByUserId,
    getUserById
} from '../../api/apiService';
import {Rating} from "../../models/rating";
import {Store} from "../../models/store";
import {User} from "../../models/user";
import {BACKEND_URL} from '../../api/apiConstants';
import RatingsTable from "../ProfileComponent/RatingsTableComponent";
import StoreItem from "../StoresComponent/StoreItemComponent/StoreItemComponent";
import {RankUtils} from "../../utils/Ranks";

const UserComponent = () => {
    const {user} = useLogin();
    const {userId} = useParams<{ userId: string }>();
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const navigate = useNavigate();
    const [ratingAmount, setRatingAmount] = useState<number>(0);
    const [upvoteCount, setUpvoteCount] = useState<number>(0);
    const [upvotesGiven, setUpvotesGiven] = useState<number>(0);
    const [topStores, setTopStores] = useState<{ store: Store, average: number }[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            let parsedUserId: number | null = null;
            try {
                if (userId) {
                    parsedUserId = parseInt(userId, 10);
                    const fetchedUser: User = await getUserById(parsedUserId);
                    setSelectedUser(fetchedUser);
                    await fetchRatings();
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        fetchData();
    }, [user]);

    useEffect(() => {
        if (selectedUser) {
            fetchRatings();
            fetchUpvoteCount();
            fetchUpvotesGivenCount();
        }
    }, [selectedUser]);

    const fetchRatings = async () => {
        try {
            if (user && selectedUser?.id) {
                const mappedRatings: Rating[] = await fetchRatingsByUserId(selectedUser.id);
                setRatingAmount(mappedRatings.length);
                const topThreeRatedStores = await getTopThreeRatedStores(mappedRatings);
                setTopStores(topThreeRatedStores);
            }
        } catch (error) {
            console.error('Error fetching data in ProfileComponent:', error);
        }
    };

    const fetchUpvoteCount = async () => {
        try {
            if (user && selectedUser?.id) {
                const totalUpvoteCount = await fetchTotalUpvotesByUserId(selectedUser.id);
                setUpvoteCount(totalUpvoteCount);
            }
        } catch (error) {
            console.error('Error fetching data in ProfileComponent:', error);
        }
    };

    const fetchUpvotesGivenCount = async () => {
        try {
            if (user && selectedUser?.id) {
                const totalUpvotesGiven = await fetchTotalUpvotesGivenByUserId(selectedUser.id);
                setUpvotesGiven(totalUpvotesGiven);
            }
        } catch (error) {
            console.error('Error fetching data in ProfileComponent:', error);
        }
    };

    const calculateAverageRating = (rating: Rating) => {
        // Sammle alle Bewertungen in einem Array
        const values = [rating.meat, rating.bread, rating.vegetable, rating.sauce];

        // Filtere die Bewertungen heraus, die 0 sind
        const nonZeroValues = values.filter(value => value !== 0);

        // Wenn alle Werte 0 sind, gib 0 als Durchschnitt zur�ck
        if (nonZeroValues.length === 0) {
            return 0;
        }

        // Berechne den Durchschnitt der verbleibenden Werte
        const average = nonZeroValues.reduce((acc, value) => acc + value, 0) / nonZeroValues.length;

        // Runde den Durchschnitt auf eine Dezimalstelle
        return Math.round(average * 10) / 10;
    };

    const getTopThreeRatedStores = async (ratings: Rating[]): Promise<{ store: Store, average: number }[]> => {
        const topRated = ratings
            .map(rating => ({
                store_id: rating.store_id,
                average: calculateAverageRating(rating)
            }))
            .sort((a, b) => b.average - a.average)
            .slice(0, 3);

        return await Promise.all(topRated.map(async ({store_id, average}) => {
            const store = await fetchStoreById(store_id);
            return {store, average};
        }));
    };

    const handleCardClick = (storeId: number) => {
        navigate(`/store/${storeId}`);
    };

    return (
        <div className='page-holder'>
            <div className='body-wrapper'>
                <Row>
                    <Col lg={3} md={6} xs={12} className="position-relative">
                        <div className='profile-picture-container'>
                            <img className='profile-picture' src={`${BACKEND_URL}/${selectedUser?.picture}`} alt="Profile"/>
                        </div>
                    </Col>
                    <Col lg={9} md={6}>
                        <div className='user-container'>
                            <div className="username">
                                <>
                                    <h3><i className="bi bi-person-fill"></i> {selectedUser?.username}</h3>
                                </>
                            </div>
                            <div className='user-info-container'>
                                <div>
                                    <i className="bi bi-trophy-fill"></i>
                                    <b> Rang:</b> {RankUtils.getRank(upvoteCount + upvotesGiven + (0.5 * ratingAmount))}
                                </div>
                                <div>
                                    <i className="bi bi-calendar-fill"></i> <b>Mitglied
                                    seit:</b> {selectedUser?.created_at.toLocaleDateString()}
                                </div>
                                <div>
                                    <i className="bi bi-star-fill"></i> <b>Bewertungen:</b> {ratingAmount}
                                </div>
                                <div>
                                    <i className="bi bi-hand-thumbs-up-fill"></i> <b>Erhaltene
                                    Upvotes:</b> {upvoteCount}
                                </div>
                            </div>

                        </div>
                    </Col>
                </Row>
                <Row className="mt-5">
                    {topStores[0] && (
                      <Col className="top-store-box" md={4} xs={12}>
                            <div className="top-store-box text-center top-store-box profile-store-rank profile-rank-1">
                                <StoreItem item={topStores[0].store}
                                           onItemClick={() => handleCardClick(topStores[0].store.id)}
                                           alternativeRating={parseFloat(topStores[0].average.toFixed(2))}
                                />
                                <div className="profile-rank-label">#1</div>
                            </div>
                        </Col>
                    )}
                    {topStores[1] && (
                        <Col className="top-store-box" md={4} xs={12}>
                            <div className="top-store-box text-center top-store-box profile-store-rank profile-rank-2">
                                <StoreItem item={topStores[1].store}
                                           onItemClick={() => handleCardClick(topStores[1].store.id)}
                                           alternativeRating={parseFloat(topStores[1].average.toFixed(2))}
                                />
                                <div className="profile-rank-label">#2</div>
                            </div>
                        </Col>
                    )}
                    {topStores[2] && (
                        <Col className="top-store-box" md={4} xs={12}>
                            <div className="top-store-box text-center top-store-box profile-store-rank profile-rank-3">
                                <StoreItem item={topStores[2].store}
                                           onItemClick={() => handleCardClick(topStores[2].store.id)}
                                           alternativeRating={parseFloat(topStores[2].average.toFixed(2))}
                                />
                                <div className="profile-rank-label">#3</div>
                            </div>
                        </Col>
                    )}
                </Row>
                <Row>
                    <RatingsTable userId={selectedUser?.id} username={selectedUser?.username}></RatingsTable>
                </Row>
            </div>
        </div>
    );

}

export default UserComponent;
