import React, {useEffect, useState} from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import './CommentCardComponent.scss';

const CommentSectionFilterBarComponent: React.FC<{ currentFilter: string; onFilterChange: (filter: string) => void }> = ({ currentFilter, onFilterChange }) => {
    const [selectedFilter, setSelectedFilter] = useState<string>(currentFilter);

    useEffect(() => {
        setSelectedFilter(currentFilter);
    }, [currentFilter]);

    const handleFilterChange = (filter: string) => {
        setSelectedFilter(filter);
        onFilterChange(filter);
    };

  return (
    <ButtonGroup className='shadow-box'>
      <ToggleButton
        id="popular"
        type="radio"
        variant="secondary"
        name="filterOptions"
        value="popular"
        checked={selectedFilter === 'popular'}
        onChange={() => handleFilterChange('popular')}
        className='text-white'
      >
        Popular
      </ToggleButton>
        <ToggleButton
            id="newest"
            type="radio"
            variant="secondary"
            name="filterOptions"
            value="newest"
            checked={selectedFilter === 'newest'}
            onChange={() => handleFilterChange('newest')}
            className='text-white'
        >
            Neueste
        </ToggleButton>
      <ToggleButton
        id="oldest"
        type="radio"
        variant="secondary"
        name="filterOptions"
        value="oldest"
        checked={selectedFilter === 'oldest'}
        onChange={() => handleFilterChange('oldest')}
        className='text-white'
      >
        Älteste
      </ToggleButton>
    </ButtonGroup>
  );
};

export default CommentSectionFilterBarComponent;
