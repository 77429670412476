import React from 'react';
import { Badge } from 'react-bootstrap';

interface CommentRatingComponentProps {
    rating: number;
    bgColor: string;
    text: string;
    badgeSize?: 'xsmall' | 'small' | 'medium' | 'large';
}

const RatingBadgeComponent: React.FC<CommentRatingComponentProps> = ({ rating, bgColor, text, badgeSize = 'medium' }) => {
    const headingTag = {
        xsmall: 'h6',
        small: 'h5',
        medium: 'h4',
        large: 'h2',
    }[badgeSize];

    return (
      <div className='text-center'>
          {React.createElement(headingTag, null,
            <Badge pill bg={bgColor}>
                {rating !== 0 ? rating : '-'}
            </Badge>
          )}
          <div>{text}</div>
      </div>
    );
};

export default RatingBadgeComponent;
