import React from 'react';
import './DonateButton.scss';

const DonateButton = () => {
    const openPayPalMe = () => {
        const paypalMeLink = 'https://www.paypal.me/juliuspflueger';
        window.open(paypalMeLink, '_blank');
    };

    return (
        <button className="paypal-donate-button" onClick={openPayPalMe}>
            <span className="paypal-logo"></span>
            Buy us a Kebap
        </button>
    );
};

export default DonateButton;